
/*---=====================================================
   <!--Dharam vir infotech Header Area Css Style One-->
=========================================================---*/
.header-area {
    margin-bottom: -106px;
}

.header-menu {
    text-align: right;
}

.header-menu ul {
    display: inline-block;
}

.header-menu ul li {
    display: inline-block;
    margin-right: 35px;
}

.header-menu ul li a {
    display: inline-block;
    font-weight: 600;
    font-size: 17px;
    padding: 40px 0;
    text-transform: capitalize;
    color: #fff;
    transition: 0.5s;
}

.header-menu ul li a:hover{
    color:#0c5adb;
}

.header-button {
    display: inline-block;
}

.header-button a {
    padding: 14px 34px;
    color: #fff;
    background: #0c5adb;
    border: 1px solid #0c5adb;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: .5s;
    text-transform: capitalize;
    font-weight: 600;
}

.header-button a:hover{
    color:#0c5adb;
    background:#fff;
}

.header-menu ul li i {
    font-size: 12px;
    color: #fff;
    position: relative;
    left: 6px;
    top: -1px;
    transition: 0.5s;
}


.header-menu ul li a:hover i{
    color:#0c5adb;
}

.header-search-icon i {
    display: inline-block;
    color: #ffff;
    transition: 0.5s;
}

.header-search-icon i:hover{
    color: #0c5adb;
}

/*sub-menu*/
.header-menu ul li.menu-item-has-children {
    position: relative;
}

.header-menu ul li .sub-menu {
    position: absolute;
    width: 200px;
    display: block;
    text-align: left;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transition: 0.5s;
    top: 150%;
    z-index: 2;
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.3);
    border-top: 5px solid #0c5adb;
    border-radius: 3px 3px 0 0;
}

.header-menu ul li:hover .sub-menu{
    opacity: 1;
    top: 100%;
    visibility: visible;
}

.header-menu ul li .sub-menu li a {
    display: block;
    padding: 8px 19px !important;
    color: #1e1e1e;
    width: 200px;
    text-transform: capitalize;
    font-size: 16px;
    font-weight: 600;
    transition: 0.5s;
    position: relative;
    z-index: 1;
}

.header-menu ul li .sub-menu li a:hover{
    color: #fff !important;
}

.header-menu ul li .sub-menu li a:before{
    position: absolute;
    content: "";
    width: 100%;
    height: 0;
    left: 0;
    bottom: 0;
    background:#0c5adb;
    z-index: -1;
    transition: 0.5s;
}

.header-menu ul li .sub-menu li a:hover:before{
    height:100%;
    top: 0;
}


.sticky {
    left: 0;
    margin: auto;
    position: fixed !important;
    top: 0;
    width: 100%;
    -webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    background: #041424 !important;
    transition: 0.5s;
    z-index: 999 !important;
    -webkit-animation: 300ms running fadeInDown;
    animation: 500ms running fadeInUp;
    animation-name: slideInDown;
}

/*---=====================================================
   <!--Toptech Hero Area Css Style One-->
=========================================================---*/
.style-one.hero-area {
    background: url(../../assets/images/home-1/hero-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 945px;
}

.style-one .hero-content h4 {
    font-size: 25px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    margin: 0 0 21px;
}

.style-one .hero-content h1 {
    font-size: 74px;
    color: #fff;
    font-weight: 700;
    line-height: 66px;
    text-transform: uppercase;
}

.style-one .hero-content p {
    width: 48%;
    color: #fff;
    font-size: 17px;
    line-height: 25px;
    margin: 26px 0 0;
}

.style-one.hero-button{
    margin-top: 49px;
}

.style-one .hero-button.style-one a {
    display: inline-block;
    background: #0c5adb;
    border: 1px solid #0c5adb;
    color: #fff;
    padding: 13px 45px;
    font-weight: 500;
    font-size: 17px;
    margin-right: 14px;
    transition: 0.5s;
}

.style-one .hero-button.style-one a:hover{
  background: transparent;
  border: 1px solid #ffffff6e;
}

.style-one a.button-two {
    background: transparent !important;
    border: 1px solid #ffffff6e !important;
    padding: 12px 45px !important;
    transition: 0.5s;
}

.style-one a.button-two:hover{
  background:#0c5adb !important;
  color:#fff;
 border: 1px solid #0c5adb !important;
}


/*---=====================================================
   <!--Toptech Feature Area Css Style One-->
=========================================================---*/
.style-one.feature-area {
    padding: 0px 0 137px;
}

.style-one.feature-area .row.margin-top {
    margin-top: -91px;
}

.style-one .flip-box {
    background-color: transparent;
    width: 300px;
    height: 185px;
    perspective: 1000px;
}

.style-one .flip-box-inner {
    position: relative;
    width: 100%;
    height: 174%;
    text-align: left;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.style-one .flip-box:hover .flip-box-inner {
  transform: rotateY(180deg);
}

.style-one .flip-box-front, .flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.style-one .flip-box-front {
    padding: 36px 25px 0;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 3px rgba(0,0,0,0.20);
}

.style-one .flip-box-content h4 {
    font-size: 23px;
    font-weight: 700;
    margin: 26px 0 8px;
}

.style-one .flip-box-content p {
    margin: 0 0 28px;
}

.style-one .flip-box-button a {
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Rajdhani';
    text-transform: uppercase;
    color: #000;
}

.style-one .flip-box-button i {
    display: inline-block;
    font-size: 16px;
    position: relative;
    color: #000;
    padding-left: 6px;
    font-weight: 600;
}

.style-one .flip-box-back {
    background-color:#0C5ADB;
    transform: rotateY(180deg);
    padding: 34px 24px 0;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.flip-box .flip-box-back::before {
    position: absolute;
    content: "";
    top: -112px;
    right: -112px;
    background: #fff;
    width: 224px;
    height: 224px;
    border-radius: 50%;
    transition: 0.4s;
    opacity: 0.1;
    z-index: -1;
}

.style-one .flip-box-back .flip-box-thumb img{
    filter: brightness(0) invert(1);
}

.style-one .flip-box-back .flip-box-button a,
.style-one .flip-box-back .flip-box-button i,
.style-one .flip-box-back .flip-box-content p,
.style-one .flip-box-back .flip-box-content h4{
    color:#fff !important;
} 


/*---=====================================================
   <!--Toptech About Area Css Style One-->
=========================================================---*/

.style-one.about-area {
    padding: 111px 0 115px;
    position: relative;
    overflow: hidden;
}

.style-one .about-thumb {
    position: relative;
    left: -47px;
}

.style-one .about-icon-box {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 42px;
}

.style-one .about-icon-thumb {
    color: #0c5adb;
    height: 78px;
    width: 127px;
    line-height: 74px;
    text-align: center;
    background-color: #d4e1f7;
    border-radius: 4px;
}

.style-one .about-icon-box-content h4 {
    font-size: 21px;
    font-weight: 700;
    margin: 0 0 7px 0;
}

.style-one .about-icon-box-content p {
    margin: 0;
}

.style-one .about-flex-box {
    display: flex;
    gap: 22px;
    align-items: center;
}

.style-one .about-author {
    display: flex;
    align-items: center;
    gap: 22px;
}

.style-one .about-author-thumb img {
    border-radius: 50px;
}

.style-one .about-author-content h4 {
    font-size: 19px;
    font-weight: 600;
    margin: 0 0 0px 0;
}

.style-one .about-author-content p {
    margin: 0;
    font-size: 15px;
}

.style-one .about-shape {
    position: absolute;
    bottom: -237px;
    right: 19px;
    z-index: -1;
}


/*section-title-Style One*/

.style-one.section-title.left {
    margin-bottom: 42px;
}

.style-one.section-title.center {
    margin-bottom: 48px;
}

.style-one.section-title h4 {
    font-size: 19px;
    text-transform: uppercase;
    font-weight: 600;
    color: #0c5adb;
    padding: 0 0 0 21px;
    position: relative;
    display: inline-block;
}

.style-one.section-title h4:before {
    position: absolute;
    left: 0;
    top: 0;
    width: 10px;
    height: 10px;
    background: #0c5adb;
    content: "";
    top: 5px;
    border-radius: 100%;
}

.style-one.section-title h1 {
    font-size: 48px;
    margin: 8px 0 0;
    line-height: 1;
}

.style-one.section-title span{
    color:#0c5adb;
}

.style-one.section-title p {
    margin: 20px 0 0;
}

/*toptech-button*/
.style-one.toptech-button a {
    padding: 12px 27px 12px 27px;
    font-family: 'Rajdhani';
    font-weight: 600;
    color: #fff;
    background: #0c5adb;
    display: inline-block;
    border-radius: 4px;
    border: 1px solid #0c5adb;
    position: relative;
    z-index: 1;
    overflow: hidden;
    text-transform: uppercase;
    font-size: 17px;
    margin-top: 8px;
    transition: 0.5s;
}

.style-one.toptech-button a:hover{
    color:#0c5adb;
}

.style-one.toptech-button a:before{
    position: absolute;
    content: "";
    width:0;
    height: 100%;
    right: 0;
    top: 0;
    background: #fff;
    z-index: -1;
    transition: 0.5s;
}

.style-one.toptech-button a:hover:before{
  width: 100%;
  left: 0;
}

.style-one.toptech-button i {
    display: inline-block;
    color: #fff;
    font-size: 21px;
    margin-left: 12px;
    position: relative;
    top: 2px;
    transition: 0.5s;
}

.style-one.toptech-button a:hover i{
    color:#0c5adb;
}



/*---=====================================================
   <!--Toptech Service Area Css Style One-->
=========================================================---*/
.style-one.service-area {
    background: url(../../assets/images/home-1/service-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 111px 0 102px;
    position: relative;
    z-index: 1;
}

.style-one.service-area:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: #0E1E2A;
    opacity: 0.8;
    z-index: -1;
}

.service-area .style-one.section-title h1 {
    color: #fff;
}

.style-one.service-area .flip-box-inner {
    position: relative;
    width: auto;
    height: 257px;
    text-align: left;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.style-one.service-area .flip-box-front {
    padding: 20px 22px 0;
    border-radius: 5px;
    background-color: transparent;
    display: flex;
    border: 1px solid #284961;
    gap: 25px;
}

.style-one.service-area .flip-box {
    background-color: transparent;
    perspective: 1000px;
    width: auto;
    height: auto;
    margin-bottom: 20px;
}

.style-one.service-area .flip-box-thumb {
    margin-top: 26px;
}

.style-one.service-area .flip-box-content h4 {
    color: #fff;
}

.style-one.service-area .flip-box-content p {
    color: #BCBCBC;
}

.style-one.service-area .flip-box-back {
    background-color: #0C5ADB;
    transform: rotateY(180deg);
    padding: 19px 24px 0;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    overflow: hidden;
    display: flex;
    gap: 25px;
}

.style-one.service-area .flip-box-button a {
    display: inline-block;
    font-size: 17px;
    font-weight: 600;
    font-family: 'Rajdhani';
    background: rgba(255, 255, 255, 0.1);
    text-transform: uppercase;
    color: #fff !important;
    padding: 10px 20px;
    border-radius: 3px;
}

.style-one.service-area .flip-box-button i {
    display: inline-block;
    font-size: 16px;
    position: relative;
    color: #fff !important;
    padding-left: 6px;
    font-weight: 600;
}



/*---=====================================================
   <!--Toptech Work Process Area Css Style One-->
=========================================================---*/
.style-one.work-process-area {
    padding: 108px 0 89px;
}

.style-one.work-process-area .section-title.center {
    margin-bottom: 58px;
}

.style-one .single-process-box {
    text-align: center;
    padding: 0 9px 0;
    position: relative;
    margin-bottom: 30px;
    z-index: 1;
}

.style-one .single-process-box:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: -64px;
    top: 47px;
    background: url(../../assets/images/home-1/process-shape.png);
    background-repeat: no-repeat;
    z-index: -1;
}

.single-process-box.before-transprant:before {
   background: transparent !important;
}

.style-one .single-process-box:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: -64px;
    top: 47px;
    background: url(../../assets/images/home-1/process-shape2.png);
    background-repeat: no-repeat;
    z-index: -1;
}

.single-process-box.after-transprant:after{
    background: transparent;
}

.style-one .process-box-thumb {
    width: 145px;
    height: 145px;
    line-height: 138px;
    border-radius: 150px;
    display: inline-block;
    color: #0c5adb;
    font-size: 65px;
    border: 2px solid #ededed;
    transition: all 500ms ease;
    text-align: center;
    position: relative;
}

.style-one .single-process-box:hover .process-box-thumb{
    border: 2px dashed #0c5adb;
}

.style-one .process-box-thumb img{
    transition: 0.5s;
}

.style-one .single-process-box:hover img{
    transform:scaleX(-1);
}

.style-one .process-content h4 {
    font-size: 23px;
    margin: 30px 0 10px;
}

.style-one .process-number span {
    position: absolute;
    right: 0%;
    top: 0px;
    z-index: 1;
    width: 38px;
    height: 38px;
    line-height: 38px;
    border-radius: 50px;
    display: inline-block;
    font-size: 18px;
    color: #fff;
    background-color: #0c5adb;
    transition: .5s;
}


/*---=====================================================
   <!--Toptech Faqs Area Css Style One-->
=========================================================---*/
.style-one.faqs-area {
    padding: 0 0 100px;
}

.style-one .row.add-faqs-bg {
    background: #E6EEFB;
    padding: 40px 26px 39px;
    border-radius: 10px;
}

.style-one .single-contact-box {
    background: #083C92;
    padding: 50px;
    padding-top: 64px;
    padding-bottom: 70px;
    border-radius: 10px;
}

.style-one .contact-content h4 {
    color: #fff;
    font-size: 38px;
    margin: 0 0 27px;
}

.style-one .single-input-box input {
    background: #3a7ae4;
    border: transparent;
    outline: 0;
    width: 100%;
    height: 50px;
    color: #fff;
    margin-bottom: 16px;
    padding: 0 19px;
    border-radius: 5px;
}

.style-one .single-input-box input::placeholder{
    color:#fff;
}

.style-one .single-input-box textarea{
    background: #3a7ae4;
    border: transparent;
    outline: 0;
    width: 100%;
    height: 120px;
    color: #fff;
    margin-bottom: 16px;
    padding: 10px 19px;
    border-radius: 5px;
}

.style-one .single-input-box textarea::placeholder{
    color:#fff;
}

.style-one .single-input-box button {
    padding: 13px 15px;
    background: #0c5adb;
    display: block;
    color: #fff;
    border-radius: 7px;
    width: 100%;
    /* font-family: 'Fira Sans'; */
    cursor: pointer;
    text-align: center;
    transition: .5s;
    border: none;
}

.col-lg-6.faqs-right {
    padding-left: 37px;
}

.faqs-container{
    overflow: hidden;
}

.faq-singular {
    position: relative;
    background:#CEDEF7;
    margin-bottom: 20px;
}

.faq-singular .faq-question {
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 16px 25px 15px 36px;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    z-index: 2;
    transition: 0.5s;
    background: #CEDEF7;
    font-family: 'Nunito', sans-serif;
}

.faq-singular.active .faq-question{
    border-bottom:1px solid #2222;
}

.faq-singular .faq-question:before {
    position: absolute;
    content: "+";
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: 400;
    transition: 0.5s;
    color:#0c5adb;
}

.faq-singular.active .faq-question:before {
    content:"-";
    font-size: 30px;
}

.faq-question img {
    padding-right: 12px;
}

.faq-answer {
    display: none;
    padding: 21px 40px 25px;
}


/*---=====================================================
   <!--Toptech Portfolio Area Css Style One-->
=========================================================---*/

.style-one.portfolio-area {
    padding: 8px 0 100px;
}

.style-one.portfolio-area .section-title.left {
    margin-bottom: 56px;
}

.style-one .single-portfolio-box {
    position: relative;
    padding: 0 10px;
}

.style-one .portfolio-thumb{
    position: relative;
}

.style-one .portfolio-thumb:before{
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    left: 50%;
    top: 0;
    background:#041424;
    opacity: 0.6;
    transition: 0.5s;
    border-radius: 10px;
}

.style-one .single-portfolio-box:hover .portfolio-thumb:before{
    width: 100%;
    left: 0;
}

.style-one .portfolio-thumb img {
    width: 100%;
    border-radius: 10px;
}

.style-one .portfolio-content {
    background: #0c5adb;
    padding: 0 0 26px;
    border-radius: 10px;
    margin: auto;
    width: 85%;
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
    transform: translate(0%, 0) rotateX(90deg);
    transition: 0.5s;
}

.style-one .single-portfolio-box:hover .portfolio-content{
    transform: translate(0%, 0) rotateX(0deg)
}

.style-one .portfolio-button a {
    display: inline-block;
    background: #fff;
    width: 100%;
    text-align: center;
    font-size: 39px;
    color: #0c5adb;
    padding: 5px 0;
    border-radius: 10px 10px 0 0;
}

.style-one .portfolio-title {
    padding: 23px 22px 0;
}

.style-one .portfolio-title a {
    display: inline-block;
    font-size: 25px;
    font-weight: 700;
    color: #fff;
    font-family: 'Rajdhani', sans-serif;
}

.style-one .portfolio-title p {
    color: #fff;
    margin: 4px 0 0;
}





/*---=====================================================
   <!--Toptech Team Area Css Style One-->
=========================================================---*/

.style-one.team-area {
    padding: 10px 0 121px;
}

.style-one.team-area .section-title.center {
    margin-bottom: 57px;
}

.style-one .single-team-box {
    position: relative;
}

.style-one .single-team-box img {
    width: 100%;
    border-radius: 10px;
}

.style-one .team-content {
    position: absolute;
    bottom: -74px;
    left: 0;
    width: 100%;
}

.style-one .team-bio {
    padding: 10px 0 17px;
    background: #0c5adb;
    text-align: center;
    width: 80%;
    position: relative;
    margin: auto;
    border-radius: 10px 10px 0 0;
     transform: perspective(500px) rotateX(90deg);
    -webkit-transition: all 400ms linear 0ms;
    transition: all 400ms linear 0ms;
}

.style-one .single-team-box:hover .team-bio{
    transform: perspective(500px) rotateX(0);
}

.style-one .team-bio h4 {
    font-size: 22px;
    padding: 0 0 4px;
    color: #fff;
}

.style-one .team-bio p{
    font-size: 16px;
    color: #fff;
    font-family: 'Rubik', sans-serif;
    font-weight: 400;
    margin: 0 0 5px;
}

.style-one .team-social-icon ul {
    position: relative;
    z-index: 1;
    left: 10%;
    width: 80%;
    background: #131323;
    text-align: center;
    padding: 14px 0 14px;
    border-radius: 0 0 10px 10px;
    transform: perspective(500px) rotateX(-90deg);
    -webkit-transition: all 400ms linear 0ms;
    transition: all 400ms linear 0ms;
    top: -1px;
}

.style-one .single-team-box:hover .team-social-icon ul{
    transform: perspective(500px) rotateX(0);
}

.style-one .team-social-icon ul li {
    display: inline-block;
}

.style-one .team-social-icon ul a {
    width: 38px;
    height: 38px;
    line-height: 41px;
    text-align: center;
    border-radius: 50px;
    background-color: #373644;
    display: inline-block;
    color: #fff;
    font-size: 14px;
    margin-right: 6px;
    position: relative;
    z-index: 1;
    overflow: hidden;
}


/*---=====================================================
   <!--Toptech Testimonial Area Css Style One-->
=========================================================---*/
.style-one.testimonial-area {
    background: url(../../assets/images/home-1/testi-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 110px 0 102px;
}

.style-one.testimonial-area .section-title.center {
    margin-bottom: 14px;
}

.style-one .testi-desc p {
    font-size: 20px;
    line-height: 34px;
    color: #041424;
    width: 65%;
    margin: auto;
    margin-top: 176px;
}

.style-one .testi-rating {
    margin-top: 20px;
}

.style-one .testi-rating ul li {
    display: inline-block;
}

.style-one .testi-rating ul li i {
    color: #FF9100;
    margin: 0 3px;
}

.style-one .testi-author h4 {
    font-size: 24px;
    margin: 53px 0 5px;
    font-weight: 600;
    position: relative;
}

.style-one .testi-author h4:before {
    position: absolute;
    content: "";
    width: 28px;
    height: 2px;
    left: 0;
    right: 0;
    top: -25px;
    background: #0B59D7;
    margin: auto;
}

.style-one.testimonial-area .owl-dots {
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    text-align: center;
}

.style-one.testimonial-area .owl-dot {
    background: url(../../assets/images/home-1/testi-1.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center;
    width: 100px;
    height: 100px;
    margin: 0 22px;
    display: inline-block;
}

.style-one.testimonial-area .owl-dot:nth-child(2){
    background: url(../../assets/images/home-1/testi-2.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center;
}

.style-one.testimonial-area .owl-dot:nth-child(3){
    background: url(../../assets/images/home-1/testi-3.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center;
}

.style-one.testimonial-area .owl-dot:nth-child(4){
    background: url(../../assets/images/home-1/testi-4.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center;
}

.style-one.testimonial-area .owl-dot.active{
    transform: scale(1.1);
    border: 6px solid #0c5adb;
    border-radius: 50%;
}

.style-one.testimonial-area .owl-prev {
    display: inline-block;
    position: absolute;
    left: 14px;
    top: 31%;
}

.style-one.testimonial-area .owl-prev i {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    background-color: #ffffff;
    line-height: 60px;
    color: #0c5adb;
    font-size: 20px;
    transition: 0.5s;
    text-align: center;
    line-height: 65px;
}

.style-one.testimonial-area .owl-next {
    display: inline-block;
    position: absolute;
    right: 9px;
    top: 31%;
}

.style-one.testimonial-area .owl-next i {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    background-color: #ffffff;
    line-height: 65px;
    color: #0c5adb;
    font-size: 20px;
    transition: 0.5s;
    text-align: center;
}

.style-one.testimonial-area .owl-next i,
.style-one.testimonial-area .owl-prev i:hover{
    background:#0c5adb;
    color:#fff;
}



/*---=====================================================
   <!--Toptech Blog Area Css Style One-->
=========================================================---*/
.style-one.blog-area {
    padding: 107px 0 100px;
}

.style-one .single-blog-box {
    margin-bottom: 20px;
    border-radius: 8px;
    background-color: #ffffff;
    border: 1px solid rgba(29, 33, 36, 0.12);
    position: relative;
    z-index: 1;
    transition: .5s;
    overflow: hidden;
    padding: 24px;
    padding-bottom: 19px;
}

.style-one .blog-thumb{
    overflow: hidden;
}

.style-one .blog-thumb img {
    width: 100%;
    transition: 0.5s;
}

.style-one .single-blog-box:hover .blog-thumb img{
    transform: scale(1.2);
}

.style-one .blog-content {
    padding: 28px 0 30px;
}

.style-one .blog-meta span {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 500;
    font-family: 'Rajdhani', sans-serif;
}

.style-one .blog-meta i {
    display: inline-block;
    color: #0c5adb;
    font-size: 15px;
    padding-right: 7px;
}

.style-one .blog-content a {
    display: inline-block;
    font-size: 25px;
    color: #232323;
    font-weight: 700;
    font-family: 'Rajdhani', sans-serif;
    margin-top: 14px;
    line-height: 30px;
    transition: 0.5s;
}

.style-one .blog-content a:hover{
    color: #0c5adb;
}

.style-one .blog-buttom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid rgba(29, 33, 36, 0.12);
    padding-top: 20px;
}

.style-one .blog-author span {
    display: inline-block;
    color: #232323;
    font-size: 16px;
    margin: 0;
    font-weight: 600;
    font-family: 'Rajdhani', sans-serif;
}

.style-one .blog-author i {
    display: inline-block;
    margin-right: 10px;
    color: #222;
}

.style-one .blog-button a {
    display: inline-block;
    color: #232323;
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    transition: .5s;
    font-size: 17px;
}

.style-one .blog-button a:hover{
    color: #0c5adb !important;
}

.style-one .blog-button i {
    display: inline-block;
    font-size: 20px;
    position: relative;
    top: 2px;
    transition: 0.5s;
     color: #232323;
}

.style-one .blog-button a:hover i{
     color: #0c5adb !important;
}


/*---=====================================================
   <!--Toptech Footer Area Css Style One-->
=========================================================---*/

.style-one.footer-area {
    background: url(../../assets/images/home-1/footer-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 119px 0 32px;
}

.style-one.footer-area.home-three {
    padding: 435px 0 32px;
}

.style-one .footer-widget-desc p {
    color: #fff;
    opacity: 0.8;
    width: 70%;
    margin: 28px 0 0;
}

.style-one .footer-widget-title h4 {
    color: #fff;
    font-size: 28px;
    font-weight: 600;
    margin: 0 0 26px;
}

.style-one .footer-widget-menu ul li a {
    display: inline-block;
    color: #fff;
    line-height: 45px;
    font-family: "Nunito";
    transition: 0.5s;
}

.style-one .footer-widget-menu ul li a:hover{
    color: #0c5adb;
}

.style-one .footer-widget-content ul li i {
    display: inline-block;
    color: #0D58D5;
    font-size: 16px;
    margin-right: 10px;
}

.style-one .footer-newslatter-box input {
    background: transparent;
    border: 1px solid #ffffff52;
    padding: 0 15px;
    height: 60px;
    width: 100%;
    color: #fff;
    border-radius: 3px;
    outline: 0;
}

.style-one .footer-newslatter-box input::placeholder {
    color: #fff;
    font-family: "Nunito";
    opacity: 0.5;
}

.style-one .footer-newslatter-box button {
    background: #0C59DB;
    color: #fff;
    border: transparent;
    outline: 0;
    padding: 12px 33px;
    border-radius: 3px;
    margin-top: 21px;
}

.style-one p.f-desc-2 {
    color: #fff;
    margin: 0 0 27px;
    opacity: 0.8;
}

.style-one .footer-bottom-area {
    border-top: 1px solid #ffffff21;
    margin-top: 107px;
    padding-top: 30px;
}

.style-one .footer-bottom-content p {
    color: #fff;
    margin: 0;
}

.style-one .footer-botton-social-icon {
    text-align: right;
}

.style-one .footer-botton-social-icon ul li {
    display: inline-block;
}

.style-one .footer-botton-social-icon ul li i {
    background: #354662;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 38px;
    color: #fff;
    font-size: 14px;
    margin-left: 7px;
    transition: 0.5s;
}

.style-one .footer-botton-social-icon ul li i:hover{
    background:#0C59DB;
}




/*---=====================================================
   <!--Toptech Home Two All Css-->
=========================================================---*/

/*Toptech Top header Area Css Style two*/

.top-header-area {
    background: #00194c;
    padding: 12px 0;
    position: relative;
    z-index: 1;
}

.top-header-area:before {
    position: absolute;
    content: "";
    width: 53%;
    height: 100%;
    left: -11px;
    top: 0;
    background: #0C59DB;
    z-index: -1;
    transform: skew(23deg);
}


.header-info ul li {
    display: inline-block;
    color: #fff;
    font-size: 15px;
    margin-right: 14px;
}

.header-info ul li i {
    display: inline-block;
    color: #fff;
    font-size: 15px;
    margin-right: 8px;
    position: relative;
    top: 2px;
}

.top-header-social-icon {
    text-align: right;
}

.top-header-social-icon ul li {
    display: inline-block;
    color: #fff;
}

.top-header-social-icon ul li.time-line {
    margin-right: 32px;
}

.top-header-social-icon ul li.time-line i {
    margin-right: 10px;
    display: inline-block;
}

.top-header-social-icon ul li i {
    color: #fff;
    display: inline-block;
    font-size: 14px;
    margin-left: 16px;
    transition: 0.5s;
}

.top-header-social-icon ul li i:hover{
   color:#0c5adb;
}


/*Toptech header Are Style two*/

.header-area.style-two {
    margin-bottom: 0;
}

.header-search-icon {
    display: inline-block;
    margin-right: 34px;
}

.style-two .header-search-icon i {
    display: inline-block;
    color: #686868;
    transition: 0.5s;
}

.style-two .header-search-icon i:hover{
    color:#0c5adb;
}

.style-two .header-menu ul li a {
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
    color: #000048;
    font-family: 'Nunito';
    transition: 0.5s;
}

.style-two .header-menu ul li a:hover{
    color:#0c5adb ;
}

.style-two .header-button a {
    padding: 11px 28px;
    color: #fff;
    background: #0c5adb;
    border: 1px solid #0c5adb;
    border-radius: 4px;
    display: inline-block;
    position: relative;
    z-index: 1;
    transition: .5s;
    text-transform: capitalize;
    font-weight: 600;
    font-family: "Nunito", ui-sans-serif;
    transition: 0.5s;
}

.style-two .header-button a:hover{
   color:#0c5adb;
   background:#fff;
}

.style-two .header-menu ul li i {
    color: #000048;
}

.style-two.sticky {
    left: 0;
    margin: auto;
    position: fixed !important;
    top: 0;
    width: 100%;
    -webkit-box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 2px 5px -2px rgba(0, 0, 0, 0.1);
    background: #fff !important;
    transition: 0.5s;
    z-index: 999 !important;
    -webkit-animation: 300ms running fadeInDown;
    animation: 500ms running fadeInUp;
    animation-name: slideInDown;
}

/*---=====================================================
   <!--Toptech hero Area Home Two All Css-->
=========================================================---*/
.style-two.hero-area {
    background: url(../../assets/images/home-2/hero-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 800px;
    overflow: hidden;
    padding-bottom: 80px;
}

.style-two .hero-content h4 {
    margin: 0 0 3px;
    font-size: 19px;
    line-height: 45px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 500;
    font-family: "Rajdhani";
}

.style-two .hero-content h1 {
    font-size: 70px;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 74px;
    font-weight: 700;
    font-family: "Rajdhani";
}

.style-two .hero-content p {
    color: #B6B9C0;
    width: 72%;
    font-size: 18px;
    margin: 11px 0 0;
}

.style-two .hero-button{
    margin-top: 49px;
}

.style-two .hero-button a {
    padding: 15px 37px 15px 42px;
    font-weight: 500;
    color: #fff;
    background: #0c5adb;
    border: 1px solid #0c5adb;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #0c5adb;
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    margin-right: 17px;
    transition: 0.5s;
}

.style-two .hero-button a:hover{
    color:#0c5adb ;
} 

.style-two .hero-button a:before{
    position:absolute;
    content: "";
    width: 0;
    height: 100%;
    right: 0;
    top: 0;
    background:#fff;
    z-index: -1;
    border-radius: 5px;
    transition: 0.5s;
}

.style-two .hero-button a:hover:before{
    width: 100%;
    left: 0;
}

.style-two .hero-button i {
    color: #fff;
    display: inline-block;
    padding-left: 11px;
    position: relative;
    top: 1px;
    transition: 0.5s;
}

.style-two .hero-button a:hover i{
    color:#0c5adb ;
} 

.style-two .hero-right{
    position: relative;
}

.style-two .hero-thumb {
    position: relative;
    left: -48px;
    top: 70px;
}

.style-two .hero-shape {
    position: absolute;
    top: 28%;
    left: -96px;
    animation: rotateme 20s linear infinite;
}


/*---=====================================================
   <!--Toptech Feature area Home Two Css-->
=========================================================---*/

.style-two.feature-area {
    padding: 22px 0 0;
}

.style-two.feature-area .row.add-marging {
    margin-top: -90px;
}

.style-two .single-feature-box {
    text-align: center;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    background: #fff;
    padding: 56px 42px 33px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin-bottom: 23px;
}

.style-two .single-feature-box:before {
    position: absolute;
    content: "";
    width: 100%;
    height:0;
    left: 0;
    bottom: 0;
    background: url(../../assets/images/home-2/feature-box-shape.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    z-index: -1;
    transition: 0.5s;
}

.style-two .single-feature-box:hover:before{
    height: 100%;
}

.style-two .feature-icon{
    position: relative;
    z-index: 1;
}

.style-two .feature-icon:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 201%;
    left: 0;
    right: 0;
    top: -19px;
    background: url(../../assets/images/home-2/feature-shape-1.png);
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.5s;
    z-index: -1;
}

.style-two.feature-area .single-feature-box:hover .feature-icon:before{
    background: url(../../assets/images/home-2/feature-shape-2.png);
    background-repeat: no-repeat;
    background-position: center;
}

.style-two .feature-content h4 {
    transition: .5s;
    padding: 35px 0 13px 0;
    font-size: 26px;
    color: #041424;
    font-weight: 700;
    font-family: "Rajdhani";
    text-align: center;
}

.style-two .single-feature-box:hover .feature-content h4 {
   color: #fff;
}

.style-two .feature-content p {
    margin: 0 0 20px;
    transition: 0.5s;
}

.style-two .single-feature-box:hover .feature-content p{
    color: #fff;
}

.style-two .feature-content a {
    padding: 0 0 0 12px;
    color: #fff;
    display: inline-block;
    margin-top: 0;
    position: relative;
    z-index: 1;
    transition: .5s;
    font-size: 16px;
    font-weight: 400;
}

.style-two .single-feature-box:hover .feature-content a{
   color: #fff;
}

.style-two .feature-content a:after {
    position: absolute;
    content: "";
    z-index: 1;
    top: 12px;
    left: 35px;
    width: 30px;
    height: 2px;
    background: #0b59db !important;
    transition: .5s;
}

.style-two .single-feature-box:hover .feature-content a:after {
    left: -24px;
    background: #fff !important;
}



/*---=====================================================
   <!--Toptech About Area Home two Css-->
=========================================================---*/
.style-two.about-area {
    background: #F3F7FD;
    padding: 380px 0 126px;
    margin-top: -287px;
}

.style-two.about-area .toptech-button a {
    margin-top: 12px;
}

.style-two .about-left {
    position: relative;
}

.style-two .about-shape {
    position: absolute;
    right: 76px;
    bottom: 27px;
    animation: bounceleft 4s linear infinite;
}

.style-two .about-conter-box {
    background: #0C59DB;
    display: inline-block;
    text-align: center;
    padding: 16px 40px;
    position: absolute;
    top: 3px;
    left: 5px;
    border-radius: 10px;
    overflow: hidden;
    z-index: 1;
}

.style-two .about-conter-box:before {
    position: absolute;
    content: "";
    width: 170px;
    height: 170px;
    left: -2px;
    top: -57px;
    background: #1164ED;
    border-radius: 50%;
    z-index: -1;
}

.style-two .about-counter-title span, 
.style-two .about-counter-title h4 {
    font-size: 56px;
    color: #ffffff;
    font-weight: 700;
    position: relative;
    padding: 0 0 16px 0;
    display: inline-block;
    font-family: "Rajdhani";
}

.style-two .about-counter-title h4:before {
    position: absolute;
    content: "";
    width: 26px;
    height: 2px;
    left: 24px;
    bottom: 4px;
    background: #fff;
}

.style-two .about-counter-title p {
    font-size: 18px;
    line-height: 24px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Rajdhani";
    text-align: center;
    padding: 20px 0 0 0;
}

.style-two .about-right {
    position: relative;
}

.style-two .single-about-icon-box {
    display: flex;
    align-items: center;
    gap: 22px;
    margin-bottom: 29px;
}

.style-two .about-icon {
    line-height: 66px;
    text-align: center;
    width: 80px;
    height: 66px;
    border-radius: 10px;
    transition: 0.5s;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    background-color: #ffff;
}

.style-two .about-icon-box-content h4 {
    margin: 0;
    font-size: 22px;
    font-weight: 600;
}

.style-two .about-shape-two {
    position: absolute;
    bottom: 0;
    left: 239px;
    animation: bounceleft 4s linear infinite;
}



/*section-title-style-two*/

.style-two.section-title {
    margin-bottom: 42px;
}

.style-two.section-title.center {
    margin-bottom: 52px;
}

.style-two.section-title h4 {
    font-size: 18px;
    color: #0c5adb;
    font-weight: 600;
    margin: 0 0 24px;
}

.style-two.section-title h1 {
    font-size: 42px;
    color: #041424;
    font-weight: 700;
    line-height: 40px;
}

.style-two.section-title p {
    margin: 19px 0 0;
}



/*---=====================================================
   <!--Toptech Service Area Home two Css-->
=========================================================---*/
.style-two.service-area {
    background: url(../../assets/images/home-2/service-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 120px 0 100px;
}

.style-two.service-area .section-title h1 {
    color: #fff;
}

.style-two .single-services-box {
    padding: 37px 0 30px 40px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    margin-bottom: 25px;
    border-radius: 5px;
}

.style-two .single-services-box:before {
    position: absolute;
    content: "";
    left: 50%;
    top:0;
    width:0;
    height:100%;
    background: #0B5ADB;
    transition: .5s;
    z-index: -1;
    border-radius: 5px;
}

.style-two .single-services-box:hover:before{
    left: 0;
    width: 100%;
}

.service-icon-thumb{
    position: relative;
}

.service-icon-thumb:before {
    position: absolute;
    content: "";
    left: -8px;
    top: -7px;
    width: 40px;
    height: 40px;
    background: #E6EEFB;
    transition: .5s;
    z-index: -1;
    border-radius: 100%;
}

.style-two .single-services-box:hover .service-icon-thumb:before{
    background: #2F73E0;
}

.service-icon-thumb img{
    transition: 0.5s;
}

.style-two .single-services-box:hover .service-icon-thumb img{
    filter: brightness(0) invert(1);
}

.style-two .service-number h1 {
    background: #E6EEFB;
    position: absolute;
    right: 0;
    top: 33px;
    transition: 0.5s;
    font-size: 26px;
    line-height: 50px;
    color: #0c5adb;
    font-weight: 600;
    font-family: "Rajdhani";
    padding: 0 14px 0 28px;
    border-radius: 30px 0 0 30px;
    margin: 0;
}

.style-two .service-content h4 {
    font-size: 26px;
    line-height: 50px;
    color: #041424;
    font-weight: 700;
    margin: 16px 0 12px 0;
    transition: 0.5s;
}

.style-two .single-services-box:hover 
.service-content h4,
.service-content p{
    color:#fff;
}

.style-two .service-content p {
    margin: 0 0 24px;
    transition: 0.5s;
    color: #686868;
}

.style-two .single-services-box:hover 
.service-content p{
    color:#fff;
}

.style-two .service-content a {
    display: inline-block;
    transition: .5s;
    font-size: 19px;
    letter-spacing: -10px;
    color: #fff;
    font-weight: 600;
    font-family: "Rajdhani";
}

.style-two .single-services-box:hover .service-content a{
    letter-spacing: 0;
}

.style-two .service-content i {
    display: inline-block;
    color: #0B59DB;
    font-size: 22px;
    transition: 0.5s;
}

.style-two .single-services-box:hover .service-content i {
    left: 7px;
    position: relative;
}

/*---=====================================================
   <!--Toptech Team Area Css Home Two-->
=========================================================---*/
.style-two.team-area {
    background: url(../../assets/images/team/team-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 119px 0 91px;
}

.style-two.team-area .section-title {
    margin-bottom: 48px;
}

.style-two.team-area .toptech-button {
    text-align: right;
    margin-top: 40px;
}

.style-two .single-team-box {
    margin-bottom: 30px;
    position: relative;
}

.style-two .team-thumb img {
    width: 100%;
}

.style-two .team-content {
    position: relative;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    background-color: #ffffff;
    text-align: center;
    padding: 24px 0 75px;
    border-radius: 4px;
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 50% 78%, 0 100%);
    transition: 0.5s;
    height: 159px;
}

.style-two .single-team-box:hover .team-content {
    background-color: #0c5adb;
    margin-top: -55px;
    height: 193px;
}

.style-two .team-content h4 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 4px;
    transition: 0.5s;
}
.style-two .single-team-box:hover .team-content p,
.style-two .single-team-box:hover .team-content h4{
    color:#fff;
}

.style-two .team-content p {
    margin: 2px 0 0;
}

.style-two .team-shape {
    position: absolute;
    bottom: -54px;
    left: -10px;
}

.style-two .team-socila-icon-box {
    position: absolute;
    left: 125px;
    bottom: -21px;
}

.style-two .team-share {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    background: #0c5adb;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.5s;
    z-index: 999;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    line-height: 45px;
    text-align: center;
}

.style-two .single-team-box.active .team-share{
    background: #fff;
    color:#0c5adb;
}

.style-two .team-social-icon {
    position: relative;
    top: -81px;
    left: -27px;
}

.style-two .team-social-icon ul li {
    list-style: none;
    margin-bottom: 5px;
    display: inline-block;
}

.style-two .team-social-icon ul li a {
    display: inline-block;
    color: #fff;
    transition: 0.5s;
    opacity: 0;
    visibility: hidden;
    position: relative;
    font-size: 16px;
    margin: 0 5px;
}

.style-two .team-social-icon.active ul li a {
    transform: translateY(0px);
    opacity: 1;
    overflow: hidden;
    visibility: visible; 
}

.style-two .team-social-icon ul li:nth-child(1) a {
    transition-delay: 0.5s;
}

.style-two .team-social-icon ul li:nth-child(2) a {
    transition-delay: 0.4s;
}

.style-two .team-social-icon ul li:nth-child(3) a {
    transition-delay: 0.2s;
}

.style-two .team-social-icon ul li:nth-child(4) a {
    transition-delay: 0s;
}


/*---=====================================================
   <!--Toptech Counter Area Css Style Two-->
=========================================================---*/
.style-two.counter-area {
    background: url(../../assets/images/home-2/counter-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 75px 0 63px;
}

.style-two .counter-box {
    text-align: center;
    margin-bottom: 30px;
}

.style-two .counter-content span,
.style-two .counter-content h4 {
    font-size: 56px;
    color: #fff;
    font-weight: 700;
    display: inline-block;
    font-family: "Rajdhani";
}

.style-two .counter-content p {
    font-size: 15px;
    color: #fff;
    opacity: 0.6;
    text-transform: uppercase;
    margin: 4px 0 0;
}



/*---=====================================================
   <!--Toptech Portfolio Area Css Home Two-->
=========================================================---*/
.style-two.portfolio-area {
    background: url(../../assets/images/home-2/portfolio-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 118px 0 100px;
}

.style-two.portfolio-area .container-fluid {
    padding: 0 102px;
}


/* portfolio menu */
.style-two .portfolio_menu {
    text-align: center;
    padding: 0 0 45px;
}

.style-two li.current_menu_item {
    color: #2E6ADC !important;
}

.style-two .portfolio_menu ul li {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    padding: 10px 32px;
    color: #021524;
    background: #fff;
    position: relative;
    z-index: 1;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    font-weight: 500;
}

.style-two .portfolio_menu ul li:before {
    position: absolute;
    content: "";
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -1;
    background: #564AC6;
    transform: scale(0);
    border-radius: 30px;
    transition: .5s;
}

.style-two .single-portfolio-box {
    position: relative;
}

.style-two .portfolio-thumb img {
    width: 100%;
    border-radius: 10px;
}

.style-two .portfolio-content {
    background: #fff;
    padding: 20px 26px 17px;
    display: flex;
    align-items: center;
    border-left: 7px solid #0C59DB;
    position: absolute;
    bottom: -65px;
    width: 75%;
    border-radius: 0 5px 5px 0;
}

.style-two .portfolio-number h1 {
    font-size: 76px;
    font-weight: 700;
    font-family: "Rajdhani";
    transition: .3s;
    position: relative;
    margin: 0 16px 0 0;
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: #DADDDF;
}

.style-two .single-portfolio-box:hover .portfolio-number h1{
    -webkit-text-stroke-color: #0B59D7;
}

.style-two .portfolio-title a {
    transition: .3s;
    font-size: 24px;
    line-height: 28px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
    display: inline-block;
    position: relative;
}

.style-two .portfolio-title a:hover{
    color:#0B59D7;
}

.style-two .portfolio-title a i {
    display: inline-block;
    background: #0B59D7;
    color: #fff;
    width: 48px;
    height: 48px;
    line-height: 48px;
    border-radius: 50% 50% 50% 0;
    text-align: center;
    position: absolute;
    top: -84px;
    right: -72px;
    opacity: 0;
    transition: 0.5s;
}

.style-two .single-portfolio-box:hover .portfolio-title a i{
    opacity: 1;
}



/*---=====================================================
   <!--Toptech Portfolio Area Css Home Two-->
=========================================================---*/
.style-two.working-proces-area {
    background: #021533;
    padding: 247px 0 90px;
    margin-top: -163px;
}

.style-two.working-proces-area .section-title.center {
    margin-bottom: 72px;
}

.style-two.working-proces-area .section-title h1 {
    font-size: 42px;
    color: #ffff;
    font-weight: 700;
    line-height: 40px;
}

.style-two .working-process-box {
    text-align: center;
    padding: 0 50px 0;
    position: relative;
    margin-bottom: 30px;
}

.style-two .working-process-box:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: -81px;
    top: 73px;
    background: url(../../assets/images/home-2/porcess-shape1.png);
    background-repeat: no-repeat;
}

.style-two .working-process-box.before-transprent:before{
    background: transparent;
}

.style-two .working-process-box:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    left: -81px;
    top: 73px;
    background: url(../../assets/images/home-2/porcess-shape2.png);
    background-repeat: no-repeat;
}

.style-two .working-process-box.after-transprent:after{
    background: transparent;
}

.style-two .process-thumb {
    position: relative;
    margin-bottom: 44px;
}

.style-two .process-thumb:before {
    position: absolute;
    content: "";
    width: 244px;
    height: 244px;
    left: 36px;
    top: -13px;
    border: 2px dashed #0c5adb;
    border-radius: 50%;
    animation: rotateme 20s linear infinite;
}

.style-two .process-number span {
    position: absolute;
    left: 12%;
    top: 13%;
    z-index: 1;
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50px;
    display: inline-block;
    font-size: 18px;
    color: #fff;
    background-color: #0c5adb;
    transition: .5s;
}

.style-two .process-number.two span {
    left: 11%;
    top: 69%;
}

.style-two .process-content h4 {
    font-size: 23px;
    color: #fff;
    margin: 20px 0 10px;
    transition: .5s;
}

.style-two .process-content p {
    opacity: 0.502;
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
    text-align: center;
    transition: .5s;
}


/*---=====================================================
   <!--Toptech Video Area Css Home Two-->
=========================================================---*/
.style-two.video-area {
    padding: 141px 0 159px;
    background: url(../../assets/images/home-2/video-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
}

.style-two.video-area .section-title h1 {
    color: #fff;
}

.style-two .video-button {
    text-align: center;
}

.style-two .video-button i {
    display: inline-block;
    background: #0B59D7;
    color: #fff;
    width: 70px;
    height: 70px;
    text-align: center;
    line-height: 72px;
    font-size: 35px;
    border-radius: 50%;
    -webkit-animation: ripple-white 1s linear infinite;
    animation: ripple-blue 1s linear infinite;
    -webkit-transition: 0.5s;
}


@keyframes ripple-blue {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3),
      0 0 0 10px rgba(255, 255, 255, 0.3), 0 0 0 20px rgba(255, 255, 255, 0.3);
  }
  100% {
    -webkit-box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3),
      0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
    box-shadow: 0 0 0 10px rgba(255, 255, 255, 0.3),
      0 0 0 20px rgba(255, 255, 255, 0.3), 0 0 0 30px rgba(255, 255, 255, 0);
  }
}


/*---=====================================================
   <!--Toptech Contact info Area Css Home Two-->
=========================================================---*/
.style-two.contact-info-area {
    background: url(../../assets/images/home-2/contact-info-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 123px 0 125px;
}

.style-two .contact-info-box {
    background: #fff;
    padding: 29px 28px 27px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: 1px solid #0B59D7;
    transition: 0.5s;
    position: relative;
    z-index: 1;
    overflow: hidden;
    gap: 29px;
}

.style-two .contact-info-box:before {
    position: absolute;
    content: "";
    width:0;
    height:100%;
    left:0;
    top:0;
    background:#0B59D7;
    border-radius: 5px;
    transition: 0.5s;
    z-index: -1;
}

.style-two .contact-info-box:hover:before{
    width: 100%;
}

.style-two .contact-info-icon i {
    display: inline-block;
    color: #0c5adb;
    border: 2px solid #0c5adb;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    line-height: 56px;
    font-size: 22px;
    transition: 0.5s;
}

.style-two .contact-info-box:hover .contact-info-icon i{
    color: #fff;
    border: 2px solid #fff;
}

.style-two .contact-info-content h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0 0 11px 0;
    transition: 0.5s;
}

.style-two .contact-info-box:hover 
.contact-info-content h4{
    color: #fff;
}

.style-two .contact-info-content p {
    font-size: 20px;
    color: #0c59d7;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 0;
    padding: 0;
    transition: 0.5s;
}

.style-two .contact-info-box:hover 
.contact-info-content p{
    color:#fff;
}


/*---=====================================================
   <!--Toptech Pricing Area Css Home Two-->
=========================================================---*/
.style-two.pricing-area {
    background: url(../../assets/images/home-2/pricing-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 139px 0 80px;
}

.style-two.pricing-area .section-title h1 {
    color: #fff;
}

.style-two .faq-singular.active .faq-question {
    background: #0B59D7;
    color: #ffff;
   padding: 17px 20px 17px 32px;
}

.style-two .faq-singular {
    position: relative;
    background: transparent;
    margin-bottom: 20px;
    border-bottom: 1px solid #cedef759;
}

.style-two .faq-singular .faq-question {
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 0 0 19px 0;
    font-weight: 600;
    font-size: 24px;
    border-radius: 5px;
    z-index: 2;
    transition: 0.5s;
    background: transparent;
    font-family: "Rajdhani";
    color: #fff;
}

.style-two .faq-answer {
    display: none;
    padding: 27px 25px 25px 0;
    opacity: 0.502;
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
}

.style-two .faq-singular .faq-question:before {
    position: absolute;
    content: "+";
    right: 0;
    top: 33%;
    transform: translateY(-50%);
    font-size: 30px;
    font-weight: 400;
    transition: 0.5s;
    color: #ffff;
}

.style-two .faq-singular.active .faq-question:before {
    content: "-";
    font-size: 30px;
    top: 32px;
    right: 16px;
}

.style-two .pricing-right {
    padding-left: 116px;
}

.style-two .single-pricing-box {
    background: #0B59D7;
    padding: 1px 37px 40px;
    position: relative;
    border-radius: 5px;
    margin-bottom: 45px;
}

.style-two a.pricing-tag {
    color: #0B59D7;
    font-weight: 600;
    font-family: "Rajdhani";
    position: absolute;
    right: 25px;
    top: -17px;
    background: #ffff;
    border-radius: 5px;
    padding: 6px 21px;
    display: inline-block;
    text-transform: uppercase;
}

.style-two span.pricing-text {
    margin: 33px 0 17px;
    transition: .5s;
    font-size: 18px;
    color: #fff;
    font-weight: 600;
    font-family: "Rajdhani";
    text-transform: uppercase;
    display: inherit;
}

.style-two h4.pricing {
    color: #fff !important;
    font-size: 40px;
    font-weight: 600;
    position: absolute;
    right: 25px;
    top: 45px;
    margin: 0;
}

.style-two h4.pricing span {
    font-size: 18px;
}

.style-two .pricing-content {
    display: flex;
    gap: 20px;
}

.style-two .pricing-icon i {
    display: inline-block;
    font-size: 40px;
    color: #fff;
    margin-top: 5px;
}

.style-two .pricing-title h4 {
    margin: 0;
    font-size: 28px;
    color: #ffff;
    font-weight: 600;
    font-family: "Rajdhani";
}

.style-two .pricing-title p {
    color: #fff;
    margin: 12px 0 8px;
    opacity: 0.8;
}

.style-two .pricing-title a {
    display: inline-block;
    border: 1px solid #fff;
    color: #fff;
    padding: 9px 25px;
    border-radius: 5px;
    margin-top: 18px;
    transition: 0.5s;
}

.style-two .pricing-title a:hover{
    background:#fff;
    color: #0c5adb;
}

/*style-two*/

.style-two .single-pricing-box.two{
    background: #fff;
}

.single-pricing-box.two span.pricing-text {
    color: #041424;
}

.single-pricing-box.two h4.pricing {
    color: #041424 !important;
}

.single-pricing-box.two h4.pricing span{
    color: #041424;
}

.single-pricing-box.two a.pricing-tag {
    color: #ffff;
    background: #0B59D7;
}

.single-pricing-box.two .pricing-icon i {
    color: #0B59D7;
}

.single-pricing-box.two .pricing-title h4{
    color: #041424;
}

.single-pricing-box.two .pricing-title p{
    color: #686868;
}

.single-pricing-box.two .pricing-title a {
    border: 1px solid #0B59D7;
    color: #041424;
    transition: 0.5s;
}

.single-pricing-box.two .pricing-title a:hover{
    background:#0c5adb;
    color:#fff;
}



/*---=====================================================
   <!--Toptech Blog Area Css Home Two-->
=========================================================---*/
.style-one.blog-area.home-two {
    background: #F3F8FC;
    padding: 117px 0 105px;
}

.style-one.home-two .blog-content {
    padding: 28px 2px 30px;
}

.style-one.home-two .blog-content a {
    font-size: 23px;
    font-weight: 600;
    line-height: 30px;
}



/*---=====================================================
   <!--Toptech Brand Area Css Inner Style One-->
=========================================================---*/
.style-two.brand-area {
    background: url(../../assets/images/home-2/brand-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 80px 0 75px;
}

.style-two .single-brand-box {
    text-align: center !important;
    position: relative;
}

.style-two .brand-thumb img {
    width: inherit !important;
    text-align: center;
    display: inline-block;
}

/*---=====================================================
   <!--Toptech Home Theree All Css-->
=========================================================---*/


.style-three.hero-area {
    background: url(../../assets/images/home-3/hero-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 750px;
    overflow: hidden;
}

.style-three .hero-right {
    position: relative;
}

.style-three .hero-thumb {
    position: relative;
    left: -104px;
    top: 60px;
}

.style-three .hero-shape {
    position: absolute;
    top: 225px;
    right: -65px;
    animation: bounceleft 4s linear infinite;
}

.style-three .hero-content h4 {
    margin: 0 0 0px;
    font-size: 20px;
    line-height: 40px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 500;
    font-family: "Rajdhani";
}

.style-three .hero-content h1 {
    font-size: 66px;
    text-transform: capitalize;
    color: #ffffff;
    line-height: 66px;
    font-weight: 700;
    font-family: "Rajdhani";
}

.style-three .hero-content p {
    color: #B6B9C0;
    width: 66%;
    font-size: 18px;
    margin: 17px 0 0;
}

.style-three .hero-button {
    margin-top: 36px;
}

.style-three .hero-button a {
    padding: 15px 37px 15px 42px;
    font-weight: 500;
    color: #fff;
    background: #0c5adb;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #0c5adb;
    position: relative;
    z-index: 1;
    font-size: 16px;
    font-family: 'Nunito', sans-serif;
    margin-right: 17px;
}

.style-three .hero-button a:hover{
    color:#0c5adb;
}

.style-three .hero-button a:before{
    position:absolute;
    content: "";
    width: 0;
    height: 100%;
    right: 0;
    top: 0;
    background:#fff;
    z-index: -1;
    border-radius: 5px;
    transition: 0.5s;
}

.style-three .hero-button a:hover:before{
    width: 100%;
    left: 0;
}

.style-three .hero-button i {
    color: #fff;
    display: inline-block;
    padding-left: 11px;
    position: relative;
    top: 1px;
    transition: 0.5s;
}

.style-three .hero-button a:hover i{
    color:#0c5adb;
}

.style-three a.button-two {
    background: #262664 !important;
    border: transparent !important;
}


/*---=====================================================
   <!--Toptech Feature Area Home Threee Css-->
=========================================================---*/
.style-three.background-images-area {
    background: url(../../assets/images/about-inner/about-images-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 2px 0 255px !important;
    position: relative;
}

/*---=====================================================
   <!--Toptech Feature Area Css Inner Style One-->
=========================================================---*/

.style-three .row.margin-top {
    margin-top: -133px;
    position: relative;
}

.style-three .single-feature-box {
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid rgba(4,20,36,0.1);
    padding: 37px 15px 18px;
    text-align: center;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
    transition: 0.5s;
}

.style-three .single-feature-box:hover{
    border: 1px solid #0c5adb;
}

.style-three .single-feature-box:before{
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    left: 50%;
    top: 0;
    background:#0c5adb;
    z-index: -1;
    transition: 0.5s;
    border-radius: 2px;
}

.style-three .single-feature-box:hover:before{
    width: 100%;
    left: 0;
}

.style-three .feature-icon img{
  transition: 0.5s;
}

.style-three .single-feature-box:hover .feature-icon img{
    filter: brightness(1) invert(1);
}

.style-three .feature-content h4 {
    font-size: 24px;
    font-weight: 600;
    margin: 32px 0 15px;
    transition: 0.5s;
}

.style-three .single-feature-box:hover .feature-content h4{
    color:#fff;
}

.style-three .feature-content p{
    transition: 0.5s;
}

.style-three .single-feature-box:hover .feature-content p{
    color:#fff;
}



/*---=====================================================
   <!--Toptech About Area Home theree Css-->
=========================================================---*/

.style-three.about-area {
    padding: 90px 0 121px;
}

.style-three.about-area .style-two.section-title {
    margin-bottom: 18px;
}

.style-three .about-left {
    position: relative;
    left: -80px;
}

.style-three .abour-award-box {
    position: absolute;
    top: 19px;
    right: 43px;
    display: flex;
    align-items: center;
    gap: 22px;
    animation: bounceleft 6s linear infinite;
}

.style-three .about-award-content h4 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    border-bottom: 2px solid #222;
    padding: 0 0 6px;
}

.style-three .about-award-content p {
    font-size: 20px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
    line-height: 32px;
    margin: 0;
}

.style-three .about-shape {
    position: absolute;
    bottom: 35px;
    left: 111px;
    animation: bounceleft 4s linear infinite;
}

.style-three .about-video-box {
    position: absolute;
    top: 40%;
    left: 42%;
}

.style-three .about-video-box i {
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #0c5adb;
    border: 2px solid #ffffff;
    display: inline-block;
    text-align: center;
    color: #fff;
    line-height: 100px;
    font-size: 30px;
}

.style-three .abour-right {
    position: relative;
}

.style-three .about-list-item ul li {
    color: #041424;
    font-weight: 500;
    line-height: 37px;
}

.style-three .about-list-item ul li i {
    display: inline-block;
    color: #0C59DB;
    font-size: 18px;
    margin-right: 15px;
}

.style-three .about-phone {
    border-radius: 10px;
    background-color: #eff6fd;
    display: flex;
    gap: 17px;
    align-items: center;
    width: 51%;
    padding: 29px 37px;
    position: absolute;
    right: -22px;
    bottom: 115px;
}

.style-three .about-phone-content h4 {
    font-size: 24px;
    margin: 0;
    font-weight: 600;
}

.style-three .about-phone-icon {
    float: left;
}

.style-three .about-shape-two {
    position: absolute;
    bottom: 24px;
    left: 44%;
    animation: bounceleft 4s linear infinite;
}



/*---=====================================================
   <!--Toptech Service Area Home theree Css-->
=========================================================---*/
.style-three.service-area {
    background: #F3F8FC;
    padding: 119px 0 100px;
}

.style-three .single-service-box {
    background-color: #fff;
    padding: 45px 38px 41px;
    position: relative;
    z-index: 99;
    border-radius: 3px;
    margin-bottom: 20px;
}

.style-three .single-service-box::before {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    height: 3px;
    width: 0;
    background-color: rgba(12, 90, 219, .1);
    transition: 0.5s;
    z-index: -1;
    border-radius: 3px;
}

.style-three .single-service-box:hover::before{
    width: 100%;
    height: 100%;
}

.style-three .service-content h4 {
    font-size: 26px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 21px 0 0;
}

.style-three .service-content p {
    color: #686868;
    margin: 12px 0 0;
}



/*---=====================================================
   <!--Toptech Counter Area Home Threee Css-->
=========================================================---*/
.style-three .row.add-bg {
    background: url(../../assets/images/home-3/counter-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 63px 40px 40px;
    margin: -103px 0;
    position: relative;
}

.style-three .single-counter-box {
    text-align: center;
    position: relative;
    margin-bottom: 25px;
}

.style-three .single-counter-box:before {
    position: absolute;
    content: "";
    width: 1px;
    height: 100px;
    right: 0;
    top: 0;
    background-image: linear-gradient(0deg, #0b59db 0%, #0b59db 10%, #ffffff 50%, #0b59db 90%, #0b59db 100%);
}

.style-three .single-counter-box.last-child:before {
    background: transparent;
}

.style-three .counter-content h4 {
    font-size: 50px;
    color: #ffffff;
    font-weight: 700;
    margin: 0;
    display: inline-block;
}

.style-three .counter-content span {
    font-size: 50px;
    color: #ffffff;
    font-weight: 700;
    margin: 0;
    display: inline-block;
}

.style-three .counter-content p {
    opacity: 0.800;
    font-size: 16px;
    color: #ffffff;
    margin: 0 0 0;
}

/*---=====================================================
   <!--Toptech Portfoli Area Home Threee Css-->
=========================================================---*/
.style-three.portfolio-area {
    background: #00004A;
    padding: 118px 0 97px;
}

.style-three.portfolio-area .container-fluid {
    padding: 0 134px 0;
}

.style-three.portfolio-area .section-title h1 {
    color: #ffff;
}

.style-three .single-portfolio-box {
    position: relative;
    margin: 0 12px 81px;
}

.style-three .portfolio-thumb img {
    width: 100%;
}

.style-three .portfolio-content {
    border-radius: 5px;
    filter: drop-shadow(0px 10px 25px rgba(175,189,199,0.2));
    background: #fff;
    padding: 30px 38px 30px;
    width: 84%;
    left: 0;
    right: 0;
    position: absolute;
    bottom: -55px;
    margin: auto;
}

.style-three .portfolio-content h4 {
    font-size: 18px;
    color: #0c5adb;
    font-weight: 500;
    margin: 0 0 11px;
}

.style-three .portfolio-content a {
    display: inline-block;
    font-size: 26px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
    transition: 0.5s;
}

.style-three .portfolio-content a:hover{
    color: #0c5adb;
}

.style-three .portfolio-content a.button {
    display: inline-block;
    background: #0C5ADB;
    width: 50px;
    height: 50px;
    line-height: 54px !important;
    color: #fff !important;
    text-align: center;
    border-radius: 50%;
    font-size: 18px !important;
    position: absolute;
    right: -19px;
    top: 40px;
    opacity: 0;
    transition: 0.5s;
}

.style-three .single-portfolio-box:hover .portfolio-content a.button{
    opacity: 1;
}

/*---=====================================================
   <!--Toptech Teastimonial Area Css Style Threee-->
=========================================================---*/
.style-three.teastimonial-area {
    background: #F3F8FC;
    padding: 120px 0 88px;
}

.style-three .single-testi-box {
    text-align: center;
    margin-bottom: 30px;
}

.style-three .testi-content {
    border-radius: 3px;
    background-color: #ffffff;
    padding: 40px 35px 70px;
}

.style-three .testi-desc p {
    font-size: 18px;
    line-height: 30px;
    color: #041424;
    margin: 22px 0 27px;
}

.style-three .testi-rating ul li {
    display: inline-block;
}

.style-three .testi-rating ul li i {
    display: inline-block;
    color: #FE9E13;
}

.style-three .teasti-author {
    margin-top: -43px;
}

.style-three .testi-author-content h4 {
    font-size: 22px;
    font-weight: 600;
    margin: 17px 0 0px;
}









/*---=====================================================
   <!--Toptech Contact Info Area Css Style Threee-->
=========================================================---*/

.style-three.contact-area-info {
    background: url(../../assets/images/home-3/contact-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 123px 0 227px;
    overflow: hidden;
}

.style-three.contact-area-info .contact-content h6 {
    font-size: 22px;
    color: #fff;
    font-weight: 500;
    margin-top: 25px;
}

.style-three.contact-area-info .contact-content h1 {
    font-size: 42px;
    color: #fff;
    font-weight: 700;
    margin-top: 27px;
}

.style-three.contact-area-info .contact-content p {
    color: #9ea1a5;
    font-size: 18px;
    line-height: 28px;
    width: 86%;
    margin: 21px 0 0;
}

.style-three.contact-area-info .contact-right{
    position: relative;
}

.style-three.contact-area-info .contact-thumb {
    position: absolute;
    top: -95px;
    right: 84px;
}

.style-three.contact-area-info .contact-shape {
    position: absolute;
    left: -51px;
    top: 120px;
    animation: dance 2s linear infinite;
}

/*---=====================================================
   <!--Toptech Choose Us Area Css Style Three-->
=========================================================---*/
.style-three .choose-us-area {
    background: #f3f8fd;
    padding: 116px 0 126px;
}

.style-three.choose-us-area {
    background: #f3f8fd;
    padding: 225px 0 126px;
}

.style-three.choose-us-area .inner-style.section-title.left p.section-desc {
    width: 84%;
}

.skill {
    margin-bottom: 18px;
    position: relative;
    overflow: hidden;
}
.skill > p {
    font-size: 18px;
    font-weight: 700;
    color: #1a1716;
    margin: 0 0 11px;
    font-family: "Rajdhani";
}
.skill:before {
    width: 85%;
    height: 7px;
    content: "";
    display: block;
    position: absolute;
    background: #D1DFF9;
    bottom: 0;
}
.skill-bar {
    width: 74% !important;
    height: 7px;
    background: #0C59DB;
    display: block;
    position: relative;
}

/* SKILL 1 */
.skill1 {
    width: 95%;
}
.skill-bar span {
    position: absolute;
    top: -40px;
    padding: 0;
    font-size: 18px;
    padding: 3px 0;
    font-weight: 500;
    color: #0C59DB;
}
.skill-bar {
    position: relative;
}

.skill1 .skill-count1 {
    right: 0;
}
/* SKILL 2 */
.skill2 {
    width: 66% !important;
}

.skill2 .skill-count2 {
    right: 0;
}

.style-three .single-pricing-box {
    border-radius: 5px;
    background-color: #ffffff;
    text-align: center;
    padding: 41px 46px 42px;
}

.style-three .pricing-content {
    border-bottom: 1px solid #7e7474de;
    margin-bottom: 17px;
    padding-bottom: 21px;
}

.style-three .pricing-content h4 {
    font-size: 26px;
    font-weight: 600;
    margin: 29px 0 5px;
}

.style-three .pricing-content h1 {
    font-size: 50px;
    margin: 0;
}

.style-three .pricing-content span {
    font-size: 16px;
    color: #686868;
    font-weight: 400;
    font-family: "Nunito";
}

.style-three .pricing-list ul li {
    line-height: 37px;
}

.style-three .pricing-button a {
    font-size: 16px;
    color: #041424;
    font-weight: 500;
    font-family: "Nunito";
    display: inline-block;
    background: #E6EEFB;
    padding: 12px 30px;
    border-radius: 5px;
    margin-top: 26px;
    transition: 1s;
}

.style-three .pricing-button a:hover{
    background: #0c5adb;
    color:#fff;
}

.style-three .single-pricing-box.two {
    background: #000049;
}

.style-three .single-pricing-box.two .pricing-content h4 {
   color:#fff;
}

.style-three .single-pricing-box.two .pricing-content p {
    color: #fff;
}

.style-three .single-pricing-box.two .pricing-content h1 {
    color:#fff;
}

.style-three .single-pricing-box.two .pricing-content span {
    color: #fff;
}

.style-three .single-pricing-box.two .pricing-list ul li {
    color:#fff;
}

.style-three .single-pricing-box.two .pricing-button a {
    background: #0c5adb;
    color:#fff;
    transition: 1s;
}

.style-three .single-pricing-box.two .pricing-button a:hover{
    background:#E6EEFB;
    color:#0c5adb;
}

/*---=====================================================
   <!--Toptech Subscribe Area Css Style Three-->
=========================================================---*/
.style-three.subscribe-area {
    background: url(../../assets/images/home-3/subscribe-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 111px 0 293px;
}

.style-three.subscribe-area .section-title.left h1 {
    color: #fff;
}

.style-three .single-subscribe-box {
    position: relative;
    left: 98px;
    top: -17px;
}

.style-three .single-subscribe-box input {
    border: 1px solid #668ee7;
    width: 80%;
    background-color: transparent;
    color: #fff;
    height: 66px;
    padding: 10px 20px 10px;
    border-radius: 5px;
    outline: 0;
}

.style-three .single-subscribe-box input::placeholder{
    color: #fff;
}

.style-three .single-subscribe-box button {
    background: #fff;
    outline: 0;
    border: none;
    padding: 13px 26px;
    right: 134px;
    top: 0px;
    border-radius: 4px;
    position: relative;
}


/*---=====================================================
   <!--Toptech Process Area Css style-three-->
=========================================================---*/
.process-area.style-three {
    padding: 0 0 120px;
}

.style-three.process-area .row.add-bg {
    background: url(../../assets/images/home-3/process-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 116px 0 77px;
    margin-top: -200px;
    border-radius: 5px;
}

.style-three .single-process-box {
    text-align: center;
    padding: 0 50px 0;
}

.style-three .process-icon {
    display: inline-block;
    width: 80px;
    height: 80px;
    border-radius: 40px;
    background-color: #0c59d7;
    text-align: center;
    line-height: 80px;
    position: relative;
    margin-bottom: 40px;
}

.style-three .process-icon:before {
    position: absolute;
    content: "";
    width: 100px;
    height: 100px;
    border: 2px dashed #0c5adb;
    top: -10px;
    left: -10px;
    border-radius: 50%;
}

.style-three .process-content h4 {
    display: inline-block;
    margin: 0 0 9px;
    font-size: 26px;
    font-weight: 600;
}


.style-one.blog-area.style-three {
    background: #F3F8FC;
    padding: 340px 0 418px;
    margin-top: -239px;
}

/*---=====================================================
   <!--Toptech Contact Area Css Style Three-->
=========================================================---*/
.style-three .add-white-bg {
    padding: 39px 37px 38px;
    border-radius: 10px;
    background-color: #ffffff;
    margin: -313px 0;
    position: relative;
}

.style-three .single-contact-box {
    margin-right: 60px;
}

.style-three .contact-contetn h4 {
    font-size: 36px;
    margin: 0 0 35px;
}

.style-three .single-input-box input {
    border: 2px solid rgba(4,20,36,0.1);
    width: 100%;
    height: 60px;
    padding: 0 20px;
    border-radius: 3px;
    outline: 0;
    margin-bottom: 27px;
}

.style-three .single-input-box input::placeholder{
    color: #686868;
    font-weight: 300;
    font-family: "Nunito";
}

.style-three .single-input-box select {
    border: 2px solid rgba(4,20,36,0.1);
    width: 100%;
    height: 60px;
    padding: 0 20px;
    border-radius: 3px;
    outline: 0;
}

.style-three .single-input-box textarea {
    border: 2px solid rgba(4,20,36,0.1);
    width: 100%;
    height: 160px;
    padding: 17px 20px;
    border-radius: 3px;
    outline: 0;
    margin-bottom: 27px;
}

.style-three .single-input-box textarea::placeholder{
    color: #686868;
    font-weight: 300;
    font-family: "Nunito";
}

.style-three .massage-sent-button button {
    border-radius: 3px;
    background-color: #0c5adb;
    border: 2px solid #0c5adb;
    color: #fff;
    padding: 15px 38px;
    transition: 0.5s;
}

.style-three .massage-sent-button button:hover{
    color:#0c5adb;
    background:#fff;
}

.style-three .single-contact-info-box {
    background: url(../../assets/images/home-3/contact-info-box-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 60px 49px 49px;
    border-radius: 10px;
}

.style-three .info-content {
    margin-bottom: 50px;
}

.style-three .info-content h4 {
    color: #fff;
    font-size: 30px;
    margin: 0;
}

.style-three .contact-info-box {
    display: flex;
    align-items: center;
    gap: 17px;
    margin-bottom: 48px;
}

.style-three .contact-info-icon i {
    display: inline-block;
    color: #fff;
    border: 2px solid #fff;
    width: 53px;
    height: 53px;
    border-radius: 50%;
    text-align: center;
    line-height: 53px;
}

.style-three .contact-info-content p {
    font-size: 15px;
    color: #ffffff;
    font-weight: 400;
    margin: 0;
}

.style-three .contact-info-content h4 {
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
    margin: 1px 0 0;
}





.style-one.footer-area.style-three {
    background: url(../../assets/images/home-1/footer-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 435px 0 32px;
}

/*---=====================================================
   <!--Toptech Header Area Css-->
=========================================================---*/
.header-area.inner-style {
    position: relative;
    z-index: 1;
}

.inner-style .header-menu {
    text-align: right;
}

.inner-style .header-menu ul {
    display: inline-block;
}

.inner-style .header-menu ul li {
    display: inline-block;
    margin-right: 36px;
}

.inner-style .header-menu ul li a {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 36px 0;
    transition: 0.5s;
}

.inner-style .header-menu ul li a:hover{
    color: #0c5adb;
}

.inner-style .header-button {
    display: inline-block;
}

.inner-style .header-button a {
    display: inline-block;
    background: #0c5adb;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    padding: 11px 31px;
    border-radius: 8px;
    transition: 0.5s;
}

.inner-style .header-button a:hover{
    background: #fff;
    color: #0c5adb;
}


/*---=====================================================
   <!--Toptech Breadcumb Area Css-->
=========================================================---*/
.breadcumb-area {
    background: url(../../assets/images/about-inner/breadcumb-bg.png);
    padding: 211px 0 200px;
    background-repeat: no-repeat;
    background-size: cover;
    margin-top: -100px;
    position: relative;
}

.breadcumb-content h4 {
    font-size: 55px;
    color: #fff;
}

.breadcumb-content ul {
    border: 2px solid #ffffff47;
    display: inline-block;
    padding: 8px 33px;
    border-radius: 5px;
    margin-top: 26px;
}

.breadcumb-content ul li {
    display: inline-block;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    font-family: "Rajdhani";
    margin: 0 4px;
}

.breadcumb-content ul li a {
    display: inline-block;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    cursor: pointer;
}

/*---=====================================================
   <!--Toptech Images Area Css Inner Style One-->
=========================================================---*/
.inner-style-one.images-area {
    background: url(../../assets/images/about-inner/about-images-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 100px 0 255px;
    position: relative;
}

.inner-style-one .images-thumb {
    position: absolute;
    right: 0;
    top: -399px;
}



/*---=====================================================
   <!--Toptech About Area Css Inner Style One-->
=========================================================---*/
.inner-style-one.about-area {
    background: url(../../assets/images/about-inner/about-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 246px 0 125px;
    margin-top: -154px;
}

.inner-style-one .about-left {
    position: relative;
    z-index: 1;
    left: -68px;
}

.inner-style-one .abour-award-box {
    background: #041424;
    display: flex;
    align-items: center;
    padding: 25px 49px 22px;
    width: 47%;
    border-radius: 5px;
    gap: 23px;
    position: absolute;
    top: 49px;
    right: -30px;
    animation: bounceleft 4s linear infinite;
}

.inner-style-one .about-award-content h4 {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    margin: 0 0 4px;
    border-bottom: 1px solid #fff;
    padding-bottom: 6px;
}

.inner-style-one .about-award-content p {
    font-size: 20px;
    color: #fff;
    font-weight: 600;
    font-family: "Rajdhani", sans-serif;
    margin: 0;
}

.inner-style-one .about-conuter-box {
    background: #0c5adb;
    text-align: center;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    line-height: 81px;
    padding-top: 14px;
    border: 6px solid #fff;
    position: absolute;
    bottom: 107px;
    left: 163px;
}

.inner-style-one .about-counter-content {
    margin-top: -14px;
}

.inner-style-one .about-counter-content span,
.inner-style-one .about-counter-content h4 {
    color: #fff;
    font-size: 45px;
    display: inline-block;
    font-weight: 700;
    font-family: "Rajdhani";
}

.inner-style-one .about-counter-content p {
    color: #fff;
    margin: -13px 0 0;
    line-height: 0;
}

.inner-style-one .about-shape {
    position: absolute;
    bottom: 11px;
    left: 152px;
    z-index: -1;
    animation: bounce 4s linear infinite;
}

.inner-style-one .abour-right {
    padding-left: 40px;
}

.inner-style-one .about-list-item ul li {
    color: #041424;
    margin: 0;
    line-height: 35px;
}

.inner-style-one .about-list-item ul li i {
    display: inline-block;
    color: #0c5adb;
    font-size: 26px;
    position: relative;
    top: 4px;
    margin-right: 12px;
}

.inner-style-one .about-text {
    display: flex;
    align-items: center;
    gap: 27px;
}

.inner-style-one .about-number h4 {
    font-size: 22px;
    font-weight: 600;
    color: #0c5adb;
    border: 2px solid #0c5adb;
    width: 65px;
    height: 65px;
    text-align: center;
    border-radius: 50%;
    line-height: 65px;
}

.inner-style-one .about-text-content h4 {
    font-size: 24px;
    margin: 0 0 0;
    font-weight: 600;
}

.inner-style-one .about-text-content p {
    font-size: 18px;
    color: #686868;
    margin: 0px 0 0;
}

.inner-style-one .about-company {
    background-color: #eff6fd;
    padding: 23px 29px 27px;
    display: flex;
    border-radius: 7px;
    margin-top: 38px;
}

.inner-style-one .about-company-icon i {
    display: inline-block;
    font-size: 40px;
    color: #0c5adb;
    margin-right: 20px;
}

.inner-style-one .about-company-desc p {
    font-size: 18px;
    font-style: italic;
    margin: 0;
}



/*section-title-inner-style*/
.inner-style.section-title.left {
    margin-bottom: 27px;
}
.inner-style.section-title.left h4 {
    font-size: 18px;
    color: #0c5adb;
    font-weight: 600;
    margin: 0 0 17px;
}

.inner-style.section-title.left h1 {
    font-size: 42px;
    font-weight: 700;
    line-height: 40px;
}

.inner-style.section-title.left p.section-desc {
    margin: 0;
    width: 100%;
    margin-top: 19px;
}
/*section-title-center*/
.inner-style.section-title.center {
    margin-bottom: 27px;
}
.inner-style.section-title.center h4 {
    font-size: 18px;
    color: #0c5adb;
    font-weight: 600;
    margin: 0 0 17px;
}

.inner-style.section-title.center h1 {
    font-size: 42px;
    font-weight: 700;
    line-height: 40px;
}

.inner-style.section-title.center p.section-desc {
    margin: 0;
    width: 100%;
    margin-top: 19px;
}

.inner-style.toptech-button a {
    display: inline-block;
    background: #0c5adb;
    border: 1px solid #0c5adb;
    color: #fff;
    padding: 14px 35px;
    font-size: 16px;
    font-weight: 500;
    border-radius: 5px;
    margin-top: 38px;
    position: relative;
    transition: 0.5s;
    z-index: 1;
}

.inner-style.toptech-button a:hover{
    color: #0c5adb;
}

.inner-style.toptech-button a:before{
    position: absolute;
    content: "";
    width:0;
    height: 100%;
    right: 0;
    top: 0;
    background: #fff;
    z-index: -1;
    transition: 0.5s;
    border-radius: 5px;
}

.inner-style.toptech-button a:hover:before{
  width: 100%;
  left: 0;
}

.inner-style.toptech-button i {
    display: inline-block;
    color: #fff;
    font-size: 24px;
    position: relative;
    top: 4px;
    left: 3px;
    transition: 0.5s;
}

.inner-style.toptech-button a:hover i{
    color: #0c5adb;
}



/*---=====================================================
   <!--Toptech Testimonial Area Css Inner Style One-->
=========================================================---*/
.inner-style-one.testimonial-area {
    background: url(../../assets/images/about-inner/testi-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 121px 0 102px;
}

.inner-style-one .testi-desc p {
    font-size: 20px;
    line-height: 34px;
    color: #041424;
    width: 65%;
    margin: auto;
    margin-top: 176px;
}

.inner-style-one .testi-rating {
    margin-top: 20px;
}

.inner-style-one .testi-rating ul li {
    display: inline-block;
}

.inner-style-one .testi-rating ul li i {
    color: #FF9100;
    margin: 0 3px;
}

.inner-style-one .testi-author h4 {
    font-size: 24px;
    margin: 53px 0 5px;
    font-weight: 600;
    position: relative;
}

.inner-style-one .testi-author h4:before {
    position: absolute;
    content: "";
    width: 28px;
    height: 2px;
    left: 0;
    right: 0;
    top: -25px;
    background: #0B59D7;
    margin: auto;
}

.testimonial-area .owl-dots {
    position: absolute;
    top: 36px;
    left: 0;
    right: 0;
    text-align: center;
}

.inner-style-one.testimonial-area button.owl-dot {
    background: url(../../assets/images/about-inner/testi-1.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center;
    width: 100px;
    height: 100px;
    margin: 0 22px;
}

.inner-style-one.testimonial-area button.owl-dot:nth-child(2){
    background: url(../../assets/images/about-inner/testi-2.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center;
}

.inner-style-one.testimonial-area button.owl-dot:nth-child(3){
    background: url(../../assets/images/about-inner/testi-3.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center;
}

.inner-style-one.testimonial-area button.owl-dot:nth-child(4){
    background: url(../../assets/images/about-inner/testi-4.png) !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center center;
}

.inner-style-one.testimonial-area button.owl-dot.active{
    transform: scale(1.1);
    border: 6px solid #0c5adb;
    border-radius: 50%;
}

.inner-style-one.testimonial-area button.owl-prev {
    display: inline-block;
    position: absolute;
    left: 14px;
    top: 31%;
}

.inner-style-one.testimonial-area button.owl-prev i {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    background-color: #ffffff;
    line-height: 60px;
    color: #0c5adb;
    font-size: 20px;
    transition: 0.5s;
}

.inner-style-one.testimonial-area button.owl-next {
    display: inline-block;
    position: absolute;
    right: 9px;
    top: 31%;
}

.inner-style-one.testimonial-area button.owl-next i {
    display: inline-block;
    width: 60px;
    height: 60px;
    border-radius: 30px;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    background-color: #ffffff;
    line-height: 60px;
    color: #0c5adb;
    font-size: 20px;
    transition: 0.5s;
}

.inner-style-one.testimonial-area button.owl-next i,
.inner-style-one.testimonial-area button.owl-prev i:hover{
    background:#0c5adb;
    color:#fff;
}



/*---=====================================================
   <!--Toptech About Area Css Inner Style Two-->
=========================================================---*/

.inner-style-two.about-area {
    background: url(../../assets/images/about-inner/about-bg.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 125px 0 125px;
}

.inner-style-two.about-area .inner-style.section-title.left {
    margin-bottom: 41px;
}

.inner-style-two.about-area .inner-style.section-title.left p.section-desc {
    margin: 0;
    width: 91%;
    margin-top: 19px;
}

.inner-style-two .about-left {
    position: relative;
    left: -60px;
}

.inner-style-two .about-video-box {
    width: 310px;
    height: 260px;
    border-radius: 5px;
    background-color: #0c5adb;
    border: 8px solid #ffffff;
    text-align: center;
    line-height: 238px;
    position: absolute;
    bottom: 30px;
    right: 1px;
}

.inner-style-two .about-video-box i {
    display: inline-block;
    background: #fff;
    width: 80px;
    height: 80px;
    line-height: 85px;
    border-radius: 50%;
    color: #0D5ADA;
    font-size: 30px;
}

.inner-style-two .abour-right {
    position: relative;
    margin-bottom: 78px;
}

.inner-style-two .abour-right:before {
    position: absolute;
    content: "";
    width: 91%;
    height: 6px;
    bottom: -45px;
    left: 0px;
    background: #22222247;
}

.inner-style-two .abour-right:after {
    position: absolute;
    content: "";
    width: 91%;
    height: 2px;
    bottom: -45px;
    left: 0px;
    background: #0C5ADB;
}

.inner-style-two .about-single-box {
    display: inline-block;
    width: 48%;
}

.inner-style-two .about-icon {
    float: left;
    padding-right: 12px;
}

.inner-style-two .about-box-content h4 {
    font-weight: 600;
    margin: 0;
    font-size: 22px;
}

.inner-style-two .about-box-content p {
    margin: 12px 0 0;
}


.inner-style-two .about-single-button {
    display: inline-block;
    margin-right: 9px;
}

.inner-style-two .about-single-button a {
    display: inline-block;
    background: #0B59DB;
    color: #fff;
    font-size: 17px;
    padding: 5px 33px;
    border-radius: 30px;
}





/*---=====================================================
   <!--Toptech Service Area Css Inner Style-->
=========================================================---*/
.inner-style.service-area {
    padding: 121px 0 60px;
    background: #F3F7FD;
}

.inner-style.service-area .section-title.center {
    margin-bottom: 90px;
}


.inner-style .single-service-box {
    background: #fff;
    text-align: center;
    transition: .5s;
    padding: 0px 20px 32px;
    filter: drop-shadow(0px 10px 50px rgba(217, 217, 217, 0.5));
    background-color: #ffffff;
    border-radius: 3px;
    margin-bottom: 64px;
}

.inner-style .service-icon {
    width: 85px;
    height: 85px;
    border-radius: 43px;
    background-color: #f3f8fd;
    border: 2px solid #ffffff;
    line-height: 80px;
    position: relative;
    top: -38px;
    margin: auto;
}

.inner-style .service-icon:before{
    position: absolute;
    content: "";
    width:100%;
    height:100%;
    left: 0;
    top: 0;
    background:#0B5AD9;
    border-radius: 50%;
    z-index: -1;
    transition: 0.5s;
}

.inner-style .single-service-box:hover .service-icon:before{
   top: 7px;
}

.inner-style .service-content h4 {
    font-size: 24px;
    font-weight: 600;
    margin: 0 0 14px;
}

.inner-style .service-content p {
    color: #686868;
}

.inner-style .service-content a {
    display: inline-block;
    background: #0C59DB;
    color: #fff;
    padding: 7px 36px;
    transition: .5s;
    border-radius: 30px;
    margin-top: 13px;
    border: 1px solid #0C59DB;
}

.inner-style .single-service-box:hover .service-content a{
    background: transparent;
    color:#0C59DB;
}

.inner-style .service-content i {
    display: inline-block;
    position: relative;
    top: 2px;
    left: 3px;
}




/*---=====================================================
   <!--Toptech Pricing Area Css Inner Style-->
=========================================================---*/
.inner-style.pricing-area {
    padding: 110px 0 116px;
    background: #F3F7FD;
    position: relative;
}

.inner-style.pricing-area .section-title.left {
    margin-bottom: 57px;
}

.inner-style.pricing-area .section-title.left.inner-style p {
    width: 89%;
    margin: 14px 0 0;
}

.inner-style .single-pricing-box {
    filter: drop-shadow(0px 10px 50px rgba(217,217,217,0.5));
    background-color: #ffffff;
    padding: 3px 3px 47px;
}

.inner-style .pricing-head {
    background: url(../../assets/images/about-inner/pricing-head-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    padding: 28px 59px 14px;
}

.inner-style .pricing-head-content h1 {
    font-size: 32px;
    color: #041424;
    margin: 0 0 4px;
}

.inner-style .pricing-body {
    padding: 27px 53px  34px;
}

.inner-style .pricing-rate h1 {
    font-size: 55px;
    color: #041424;
    margin: 0 0 16px;
}

.inner-style .pricing-rate del {
    font-size: 22px;
    font-weight: 600;
    color: #0c5adb;
    font-family: "Rajdhani";
}

.inner-style .pricing-list ul li {
    line-height: 44px;
}

.inner-style .pricing-button {
    text-align: center;
}

.inner-style .pricing-button a {
    display: inline-block;
    border-radius: 10px;
    background-color: #ffffff;
    border: 1px solid rgba(4,20,36,0.1);
    padding: 15px 61px;
    color: #131313;
    transition: 0.5s;
}

.inner-style .pricing-button a:hover{
    background: #115BD9;
    color:#fff;
}

.inner-style .tab-button {
    float: right;
    margin-top: 36px;
}

.inner-style .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    color: var(--bs-nav-pills-link-active-color);
    background-color: #021533;
    border: transparent;
}

.inner-style .nav-link {
    display: block;
    padding: 14px 35px;
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-decoration: none;
    background: #0F5AD9;
    border: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out;
    margin-left: 10px;
}

.inner-style .pricing-textar-text {
    margin-top: 72px;
}

.inner-style .pricing-textar-text p {
    font-size: 17px;
    color: #686868;
    font-weight: 500;
    font-family: "Rajdhani";
    text-align: center;
    margin: 0;
    position: relative;
}

.inner-style .pricing-textar-text p:before {
    position: absolute;
    content: "";
    top: 12px;
    left: 0;
    width: 390px;
    height: 1px;
    background-image: linear-gradient(90deg, #0b59db 0%, #f3f7fd 100%);
}

.inner-style .pricing-textar-text p:after {
    position: absolute;
    content: "";
    top: 12px;
    right: 0;
    width: 390px;
    height: 1px;
    background-image: linear-gradient(90deg, #f3f7fd 0%, #0b59db 100%);
}

/*---=====================================================
   <!--Toptech faqs Area Css Inner Style-->
=========================================================---*/

.inner-style.faqs-area {
    padding: 124px 0 101px;
    background: #F3F8FC;
}

.inner-style.faqs-area .section-title.left {
    margin-bottom: 40px;
}

.inner-style.faqs-area .section-title.left p {
    margin: 25px 78px 0 0;
}

.inner-style .faqs-thumb {
    display: inline-block;
    margin: 0 4px 0;
}

.inner-style .faqs-icon-box {
    display: flex;
    align-items: center;
    gap: 23px;
    margin: 33px 0 0;
}

.inner-style .faqs-icon-content p {
    margin: 0;
    width: 83%;
}

.inner-style .faq-singular .faq-question {
    position: relative;
    cursor: pointer;
    margin: 0;
    padding: 16px 10px 15px 23px;
    font-weight: 700;
    font-size: 18px;
    border-radius: 5px;
    z-index: 2;
    transition: 0.5s;
    background: #fff;
    font-family: 'Nunito', sans-serif;
}

.inner-style .faq-singular {
    position: relative;
    background: #fff;
    margin-bottom: 20px;
}

.inner-style .faq-singular.active .faq-question {
    border-bottom: 1px solid #2222;
    background: #0B59DB !important;
    color: #fff;
}

.inner-style .faq-singular.active .faq-question:before {
    content: "-";
    font-size: 30px;
    color: #fff;
}

.inner-style .faq-singular.active .faq-question img{
    filter: brightness(0) invert(1);
}


/*---=====================================================
   <!--Toptech Strat Project Details Area Css-->
=========================================================---*/

.project-details-area {
    padding: 123px 0 93px;
}

.project-details-content {
    margin-top: 35px;
}

.project-details-thumb img {
    width: 100%;
}

.project-details-content h4 {
    font-size: 36px;
    margin-bottom: 17px;
}

p.project-details-desc {
    font-size: 17px;
    margin-bottom: 27px;
    width: 96%;
}

.project-details-content h3 {
    font-size: 28px;
    margin-bottom: 20px;
    margin-top: 54px;
}

.project-details-item-images img {
    width: 100%;
}

.project-details-list-item h4 {
    font-size: 28px;
}

.project-details-list-item ul li i {
    display: inline-block;
    color: #0c5adb;
    font-size: 19px;
    margin-right: 11px;
    position: relative;
    top: 2px;
}

.project-details-list-item ul li {
    font-size: 17px;
    line-height: 39px;
}

a.project-details-text {
    display: inline-block;
    font-size: 28px;
    line-height: 38px;
    color: #0c5adb;
    font-weight: 700;
    margin-top: 50px;
    margin-bottom: 19px;
    font-family: "Rajdhani";
}

.project-details-right {
    background: #F6F6EE;
    padding: 29px 41px 30px;
    border-radius: 5px;
    margin-top: 50px;
}

.project-details-info {
    border-bottom: 1px solid #86868152;
    padding-bottom: 10px;
    padding-top: 9px;
}

.project-details-info:last-child{
    border-bottom: transparent;
}

.project-details-info p {
    margin-bottom: 0;
}

.project-details-info h6 {
    font-size: 18px;
    font-weight: 600;
    margin-top: 6px;
}



/*---=====================================================
   <!--Toptech Project Area Css inner style-->
=========================================================---*/
.inner-style.project-area {
    background: #f3f8fc;
    padding: 119px 0 95px;
}

.inner-style.project-area .section-title.center {
    margin-bottom: 56px;
}

.inner-style .single-project-box {
    margin-bottom: 28px;
}

.inner-style .project-thumb img{
    width: 100%;
}

.inner-style .project-content {
    background: #fff;
    padding: 30px 34px 28px;
    width: 88%;
    border-left: 5px solid #0c5adb;
    position: relative;
    margin-top: -38px;
    transition: 0.5s;
    border-radius: 3px;
    height: 117px;
}

.inner-style .single-project-box:hover .project-content {
    background: #001819;
    height: 159px;
    margin-top: -83px;
}

.inner-style .project-content h4 {
    font-size: 18px;
    margin: 0 0 12px;
    transition: 0.5s;
    color: #0c5adb;
    font-weight: 600;
    font-family: "Rajdhani";
}

.inner-style .single-project-box:hover .project-content h4{
    color: #0c5adb;
}

.inner-style .project-content a {
    display: inline-block;
    color: #001819;
    font-size: 24px;
    font-weight: 700;
    transition: 0.5s;
    font-family: "Rajdhani";
}

.inner-style .project-content a:hover{
    color:#0c5adb !important;
}

.inner-style .single-project-box:hover .project-content a {
    color: #fff;
}

.inner-style a.project-button {
    font-size: 17px !important;
    display: inherit;
    color: #fff;
    font-weight: 500;
    margin-top: 9px;
    display: none;
    transition: 0.5s;
}

.inner-style a.project-button:hover{
    color:#0c5adb !important;
}

.inner-style .single-project-box:hover a.project-button{
    display: block;
} 

.inner-style a.project-button  i {
    display: inline-block;
    color: #fff;
    font-size: 22px;
    position: relative;
    top: 3px;
    transition: 0.5s;
}

.inner-style a.project-button:hover i{
    color:#0c5adb !important;
}

.inner-style .project-shape {
    position: absolute;
    right: 14px;
    bottom: 22px;
    display: none;
    transition: 0.5s;
}

.inner-style .single-project-box:hover .project-shape{
    display: block;
}


/*sidber contact*/

.widget-sidber-contact-box {
    background: url(../../assets/images/inner-images/sidber-contact.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 5px;
    padding: 48px 43px 48px;
}

p.widget-sidber-contact-text {
    font-size: 18px;
    color: #ffffff;
    font-weight: 500;
    margin: 0;
    padding: 25px 0 13px;
}

h3.widget-sidber-contact-number {
    font-size: 24px;
    color: #ffffff;
    font-weight: 600;
    margin: 0 0 14px;
}

span.widget-sidber-contact-gmail {
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    margin: 0;
}

span.widget-sidber-contact-gmail i {
    display: inline-block;
    font-size: 17px;
    color: #0c5adb;
    position: relative;
    top: 2px;
    margin-right: 6px;
}

.widget-sidber-contact-btn  a{
    display: inline-block;
    font-size: 16px;
    color: #ffffff;
    font-weight: 400;
    text-decoration: none;
    background: #0c5adb;
    padding: 16px 98px 15px;
    border-radius: 5px;
    margin-top: 55px;
    transition: 0.5s;
    border: 2px solid #0c5adb;
}

.widget-sidber-contact-btn  a:hover{
    background: transparent;
    color:#0c5adb;
}

.widget-sidber-contact-btn a i {
    display: inline-block;
    font-size: 18px;
    position: relative;
    top: 2px;
    margin-left: 3px;
}


.style-one.inner.team-area {
    background: url(../../assets/images/team/team-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 119px 0 123px;
}

.style-one.inner.team-area .toptech-button.style-one {
    text-align: right;
    margin-top: 40px;
}


/*---=====================================================
   <!--Toptech Services Details Area Css-->
=========================================================---*/

.services-details-area {
    padding: 122px 0 121px;
}

.services-details-thumb img {
    width: 100%;
}

.services-details-content {
    padding-top: 18px;
}

h4.services-details-title {
    font-size: 36px;
    margin-bottom: 10px;
}

p.services-details-desc {
    font-size: 17px;
    margin-top: 24px;
}

.service-details-icon-box {
    display: flex;
    background: #F6F6EE;
    padding: 32px 27px 18px;
    gap: 28px;
    margin-top: 27px;
}

.service-details-box-content h4 {
    font-size: 22px;
    font-weight: 700;
    margin: 0 0 11px;
}

.services-details-text {
    background: #0c5adb;
    display: flex;
    align-items: center;
    padding: 32px 39px;
    gap: 65px;
    border-radius: 5px;
    margin-top: 55px;
    margin-bottom: 41px;
}

.service-details-text-icon {
   position: relative;
}

.service-details-text-icon:before {
    position: absolute;
    content: "";
    width: 2px;
    height: 49px;
    right: -29px;
    top: 1px;
    background: #f6f6ee61;
}

.service-details-text-content p {
    font-size: 22px;
    line-height: 30px;
    color: #ffffff;
    font-weight: 400;
    font-style: italic;
    width: 90%;
    margin: 0;
}

.services-details-area .single-benifits-box {
    margin-top: 22px;
}

.services-details-area .benifits-thumb img {
    width: 100%;
}

.services-details-area .benifits-content {
    border-radius: 5px;
    filter: drop-shadow(0px 5px 30px rgba(117,117,117,0.1));
    background-color: #ffffff;
    padding: 30px 31px 23px;
    width: 92%;
    margin: auto;
    margin-top: -28px;
}

.services-details-area .benifits-content h4 {
    font-size: 22px;
    margin: 0;
    margin-bottom: 14px;
    font-weight: 600;
    color: #001819;
}

.services-details-area .benifits-content ul li {
    line-height: 32px;
}

.services-details-area .benifits-content ul li i {
    display: inline-block;
    color: #0c5adb;
    font-size: 18px;
    position: relative;
    top: 2px;
    margin-right: 8px;
}

/*widget-sidber css*/

.widget-sidber {
    border-radius: 5px;
    background-color: #f7f6ee;
    padding: 32px 41px 29px;
    margin-bottom: 27px;
}

.widget-sidber-content h4 {
    font-size: 28px;
    font-weight: 700;
    margin: 0 0 46px;
    position: relative;
}
.widget-sidber-content h4:before {
    position: absolute;
    content: "";
    width: 29px;
    height: 2px;
    left: 0;
    bottom: -8px;
    background: #0c5adb;
}

.widget-category ul li img {
    margin-right: 12px;
    transition: 0.5s;
}

.widget-category ul li a:hover img{
    filter: brightness(0) invert(1);
}

.widget-category ul li a {
    background: #ffff;
    padding: 14px 26px;
    width: 100%;
    display: inline-block;
    margin-bottom: 14px;
    color: #001819;
    font-weight: 500;
    border-radius: 5px;
    position: relative;
    z-index: 1;
    transition: 0.5s;
}

.widget-category ul li a:hover{
    color:#fff;
}

.widget-category ul li a:before{
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    left: 50%;
    top: 0;
    background: #0c5adb;
    z-index: -1;
    border-radius: 5px;
    transition: 0.5s;
}

.widget-category ul li a:hover:before{
    width: 100%;
    left: 0;
}

.widget-category ul li i {
    color: black;
    display: inline-block;
    float: right;
    font-size: 17px;
    opacity: 0;
    transition: 0.5s;
}

.widget-category ul li a:hover i{
   opacity: 1;
   color: #fff;
}

.widget-sidber-download-button a {
    display: inline-block;
    background: #00181A;
    width: 100%;
    color: #fff;
    padding: 15px 23px;
    border-radius: 5px;
    margin-bottom: 18px;
}

.widget-sidber-download-button a.active {
   background: #0c5adb;
} 

.widget-sidber-download-button i {
    color: #0c5adb;
    display: inline-block;
    font-size: 22px;
    margin-right: 9px;
    position: relative;
    top: 3px;
}

.widget-sidber-download-button a.active i{
    color:#fff;
}


.widget-sidber-download-button span i {
    float: right;
    display: inline-block;
    font-size: 16px;
}

.widget-sidber-download-button a.active span i{
    color:#fff;
}

/*---=====================================================
   <!--Toptech Blog Details Area Css-->
=========================================================---*/
.blog-details-area {
    padding: 119px 0 119px;
}

.blog-details-thumb{
    overflow: hidden;
}

.blog-details-thumb img {
    width: 100%;
    transition: 0.5s;
}

.blog-details-thumb img:hover{
    transform: scale(1.2) skew(-10deg);
}

.blog-details-content {
    padding: 27px 36px 20px;
    border: 1px solid #2222;
}

.blog-details-area .meta-blog span {
    display: inline-block;
    margin-right: 28px;
    font-weight: 500;
}

.blog-details-area span.mate-text {
    padding-left: 36px;
    position: relative;
}

.blog-details-area span.mate-text:before {
    position: absolute;
    content: "";
    width: 20px;
    height: 2px;
    left: 5px;
    top: 11px;
    background: #0c5adb;
}

.blog-details-area .meta-blog i {
    display: inline-block;
    color: #0c5adb;
    padding-right: 9px;
}

.blog-details-area .meta-blog img {
    margin-right: 8px;
    position: relative;
    top: -1px;
}

h4.blog-details-title {
    font-size: 32px;
    margin: 12px 0 19px;
}

p.blog-details-desc {
    font-size: 17px;
    margin-bottom: 25px;
}

.blog-details-author-talk {
    display: flex;
    gap: 26px;
    background: #F7F6EE;
    padding: 41px 51px 40px;
    margin-top: 48px;
}

.blog-details-quote img {
    position: relative;
    top: 8px;
}

.blog-details-author-title p {
    font-size: 20px;
    font-style: italic;
    line-height: 30px;
    color: #001819;
}

.blog-details-quote img {
    position: relative;
    top: 8px;
    color: #001819;
}

.blog-details-author-title span {
    margin-left: 58px;
    display: inline-block;
    color: #001819;
    position: relative;
}

.blog-details-author-title span:before {
    position: absolute;
    content: "";
    width: 50px;
    height: 2px;
    left: -58px;
    top: 12px;
    background: #0c5adb;
}

h3.blog-details-title {
    font-size: 28px;
    margin: 54px 0 16px;
}

p.blog-details-desc.two {
    width: 95%;
}

.blog-details-list-item {
    margin-bottom: 40px;
}

.blog-details-list-item ul li {
    margin-bottom: 10px;
    font-size: 17px;
}

.blog-details-list-item ul li i {
    display: inline-block;
    color: #0c5adb;
    font-size: 18px;
    margin-right: 16px;
}

h3.blog-details-title.two {
    font-size: 28px;
    margin: 38px 0 16px;
}

p.blog-details-desc.three {
    width: 100%;
}

.blog-details-socila-box {
    border: 1px solid #2222;
    border-top: transparent;
    padding: 19px 35px 18px;
    border-radius: 0 0 5px 5px;
}

.blog-details-category span a {
    display: inline-block;
    background: #F6F6EE;
    padding: 6px 16px;
    font-weight: 500;
    color: #001819;
    border-radius: 3px;
    margin-right: 7px;
    transition: 0.5s;
}

.blog-details-category span a:hover{
    background:#0c5adb;
    color:#fff;
}

.blog-details-category span a.active-class {
    background:#0c5adb;
    color:#fff;
}

.blog-details-social-icon {
    text-align: right;
}

.blog-details-social-icon ul li {
    display: inline-block;
}

.blog-details-social-icon ul li i {
    display: inline-block;
    background: #1974E9;
    color: #fff;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    font-size: 13px;
    margin-left: 5px;
}

.blog-details-social-icon ul li:nth-child(2) i{
    background: #00AAE8;
}

.blog-details-social-icon ul li:nth-child(3) i{
    background: #016EAF;
}

.blog-details-social-icon ul li:nth-child(4) i{
    background: #B7071C;
}


/* Comment CSS */

.single-comment-area {
    background: #F6F6EE;
    padding: 49px 41px 56px;
    margin-top: 61px;
    border-radius: 5px;
}

.blog-details-comment-title h4 {
    font-size: 28px;
    margin: 0 0 28px;
}

.blog-details-comment {
    margin-bottom: 19px;
    position: relative;
    border: 1px solid #22222214;
    padding: 29px 29px 25px;
}

.blog-details-comment.style-two {
    margin-left: 105px;
}

.blog-details-comment-thumb {
    float: left;
    margin-right: 30px;
}

.blog-details-comment-content {
    overflow: hidden;
}

.blog-details-comment-content h2 {
    font-size: 20px;
    margin-top: 0;
    display: inline-block;
    font-weight: 700;
    margin-right: 30px;
}

.blog-details-comment-content p {
    font-size: 15px;
    margin: 13px 0 0;
}

.blog-details-comment-reply {
    position: absolute;
    right: 22px;
    top: 21px;
}

.blog-details-comment-reply a {
    background: #0c5adb;
    padding: 3px 20px;
    font-size: 14px;
    font-weight: 600;
    color: #fff;
    border-radius: 2px;
    display: inline-block;
}

.blog-details-comment.reply {
    margin-left: 70px;
    padding-top: 35px;
    border-top: 2px solid rgba(255,255,255,0.2);
}

.blog-details-contact-title h4 {
    font-size: 23px;
    font-weight: 600;
    padding-bottom: 31px;
}

.blog-details-area .contact-input-box input {
    background: #fff;
    border: transparent;
    width: 100%;
    height: 60px;
    padding: 22px;
    outline: 0;
    margin-bottom: 11px;
}

.blog-details-area .contact-input-box textarea {
    background: #fff;
    border: transparent;
    width: 100%;
    height: 170px;
    padding: 12px 22px 0;
    outline: 0;
    margin-top: 11px;
    margin-bottom: 20px;
}

.input-check-box span {
    font-size: 15px;
    margin-left: 7px;
}

.blog-details-submi-button button {
    background: #0c5adb;
    color: #fff;
    text-transform: uppercase;
    border: transparent;
    padding: 12px 26px;
    font-size: 15px;
    margin-top: 34px;
    position: relative;
    z-index: 1;
    transition: 0.5s;
    border: 2px solid #0c5adb;
    border-radius: 5px;
    font-weight: 500;
}

.blog-details-submi-button button:hover{
    color:#0c5adb;
}

.blog-details-submi-button button:before{
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    left: 50%;
    top: 0;
    background:#fff;
    transition: 0.5s;
    z-index: -1;
    border-radius: 5px;
}

.blog-details-submi-button button:hover:before{
    width: 100%;
    left: 0;
}

/*sidber Css*/

.widget_search form {
    position: relative;
}

.widget_search input {
    border-radius: 4px;
    padding-left: 20px !important;
    height: 60px;
    width: 100% !important;
    position: relative;
    color: #012549;
    outline: 0 !important;
    border: transparent !important;
}


button.icons {
    border: 0;
    font-size: 16px;
    position: absolute;
    right: 14px;
    top: 20px;
    color: #1e1e1e;
    background: transparent;
    opacity: 0.7;
}

.sidber-widget-recent-post {
    margin-bottom: 27px;
    display: flex;
    align-items: center;
    gap: 19px;
}

.recent-widget-content a {
    transition: 0.5s;
    width: 95%;
    display: inline-block;
    font-size: 18px;
    line-height: 24px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
}

.sidber-widget-recent-post:hover .recent-widget-content a{
    color: #0c5adb !important;
}

.recent-widget-content p {
    font-size: 14px;
    margin: 0;
    color:#0c5adb;
}

.widget-catefories-tags a {
    display: inline-block;
    color: #101010;
    font-weight: 500;
    background: #fff;
    padding: 6px 19px;
    margin: 6px 3px;
    position: relative;
    z-index: 1;
    font-size: 15px;
}

.widget-catefories-tags a:hover{
    color:#fff;
}

.widget-catefories-tags a:before{
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    left: 50%;
    top: 0;
    background:#0c5adb;
    transition: 0.5s;
    z-index: -1;
}

.widget-catefories-tags a:hover:before{
    width: 100%;
    left: 0;
}

/*---=====================================================
   <!--Toptech Blog Grid Area Css-->
=========================================================---*/
.blog-grid-area {
    padding: 120px 0 100px;
}

.blog-grid-area .single-blog-box {
    border-radius: 5px;
    background-color: #f3f8fd;
    padding: 20px 21px 31px;
    position: relative;
    margin-bottom: 21px;
}

.blog-grid-area .blog-thumb img {
    width: 100%;
}

.blog-grid-area .blog-content {
    padding: 25px 22px 3px;
}

.blog-grid-area .blog-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 55px;
    position: relative;
}

.blog-grid-area .blog-meta:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 2px;
    left: 0;
    bottom: -26px;
    background: #fff;
}

.blog-grid-area .blog-meta:after {
    position: absolute;
    content: "";
    width:30px;
    height: 2px;
    left: 0;
    bottom: -26px;
    background: #0c5adb;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: MOVE-BG;
  animation-name: MOVE-BG;
}
@-webkit-keyframes MOVE-BG {
  from {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  to {
    -webkit-transform: translateX(298px);
    transform: translateX(298px);
  }
}

.blog-grid-area .blog-meta span {
    font-size: 17px;
    line-height: 32px;
    color: #0c5adb;
    font-weight: 500;
    font-family: "Rajdhani";
}

.blog-grid-area .blog-title a {
    font-size: 24px;
    line-height: 32px;
    color: #041424;
    font-weight: 700;
    font-family: "Rajdhani";
}

.blog-grid-area .blog-title a:hover{
    color: #0c5adb;
}

.blog-grid-area .blog-title p {
    margin: 12px 0 0;
}

.blog-grid-area .blog-category a {
    display: inline-block;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
    background: #0C5ADB;
    padding: 7px 38px;
    position: absolute;
    top: 20px;
    left: 21px;
    border-radius: 5px 0 0 0;
    overflow: hidden;
}

.blog-grid-area .blog-category a:before {
    position: absolute;
    content: "";
    width: 30px;
    height: 30px;
    right: -15px;
    top: 6px;
    background: #001739;
    transform: rotate(45deg);
}

.blog-grid-area .blog-button i {
    background: #0D5ADE;
    display: inline-block;
    color: #fff;
    width: 46px;
    height: 46px;
    text-align: center;
    line-height: 43px;
    border-radius: 50%;
    font-size: 14px;
    transform: rotate(-50deg);
    border: 2px solid #fff;
    position: absolute;
    top: 192px;
    right: 41px;
    opacity: 0;
    transition: 0.5s;
}

.blog-grid-area .single-blog-box:hover .blog-button i{
    opacity: 1;
}


.process-area.style-three.inner {
    padding: 0 0 227px;
}

/*---=====================================================
   <!--Toptech Careers Area Css-->
=========================================================---*/
.inner-style.careers-area {
    padding: 119px 0 95px;
    background: #F5F8FD;
}

.inner-style.careers-area .row.title {
    margin-bottom: 84px;
}

.inner-style .careers-content h4 {
    color: #041424;
    font-size: 24px;
    margin: 0;
    font-weight: 600;
}

.inner-style .careers-content a {
    display: inline-block;
    color: #0c5adb;
    font-weight: 600;
    font-family: "Rajdhani";
}


.inner-style .careers-desc p {
    width: 67%;
    float: right;
    margin: 0;
}

.inner-style .careers-desc a{
    display: inline-block;
    color: #0c5adb;
    font-weight: 600;
    font-family: "Rajdhani";
}

.inner-style .job-item-content span {
    border-radius: 15px;
    background-color: #0c5adb;
    display: inline-block;
    color: #fff;
    padding: 2px 25px;
}

.inner-style span.location {
    background: transparent !important;
    font-size: 14px;
    color: #686868 !important;
    font-weight: 400;
    font-family: "Nunito";
    padding: 0 !important;
    margin-left: 18px;
}

.inner-style .job-item-content h4 {
    font-size: 26px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 19px 0 0;
}

.inner-style .job-desc p {
    margin: 0;
    width: 100%;
}

.inner-style .job-apply-button {
    text-align: right;
}

.inner-style .job-apply-button a {
    display: inline-block;
    border: 2px solid #2222;
    padding: 14px 58px;
    border-radius: 30px;
    color: #041424;
    font-weight: 500;
    transition: 0.5s;
}

.inner-style .job-apply-button a:hover{
    border: 2px solid #0C5ADB;
    background:#0C5ADB;
    color: #fff;
}

.inner-style.careers-area .row.add-border {
    border-top: 2px solid #E7E7E7;
    padding-top: 82px;
    padding-bottom: 73px;
}

.inner-style .job-requirements-content h4 {
    color: #041424;
    font-weight: 600;
    margin: 27px 0 29px;
}

.inner-style .job-requirements-content p {
    margin: 0 0 54px;
}

.inner-style .single-feature-box {
    text-align: center;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    background: #fff;
    padding: 56px 42px 33px;
    border-radius: 10px;
    position: relative;
    z-index: 1;
    margin-bottom: 30px;
}

.inner-style .single-feature-box:before {
    position: absolute;
    content: "";
    width: 100%;
    height:0;
    left: 0;
    bottom: 0;
    background: url(../../assets/images/home-2/feature-box-shape.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10px;
    z-index: -1;
    transition: 0.5s;
}

.inner-style .single-feature-box:hover:before{
    height: 100%;
}

.inner-style .feature-icon{
    position: relative;
    z-index: 1;
}

.inner-style .feature-icon:before {
    position: absolute;
    content: "";
    width: 100%;
    height: 201%;
    left: 0;
    right: 0;
    top: -19px;
    background: url(../../assets/images/home-2/feature-shape-1.png);
    background-repeat: no-repeat;
    background-position: center;
    transition: 0.5s;
    z-index: -1;
}

.inner-style .single-feature-box:hover .feature-icon:before{
    background: url(../../assets/images/home-2/feature-shape-2.png);
    background-repeat: no-repeat;
    background-position: center;
}

.inner-style .feature-content h4 {
    transition: .5s;
    padding: 35px 0 13px 0;
    font-size: 26px;
    color: #041424;
    font-weight: 700;
    font-family: "Rajdhani";
    text-align: center;
}

.inner-style .single-feature-box:hover .feature-content h4 {
   color: #fff;
}

.inner-style .feature-content p {
    margin: 0 0 20px;
    transition: 0.5s;
}

.inner-style .single-feature-box:hover .feature-content p{
    color: #fff;
}

.inner-style .feature-content a {
    padding: 0 0 0 12px;
    color: #fff;
    display: inline-block;
    margin-top: 0;
    position: relative;
    z-index: 1;
    transition: .5s;
    font-size: 16px;
    font-weight: 400;
}

.inner-style .single-feature-box:hover .feature-content a{
   color: #fff;
}

.inner-style .feature-content a:after {
    position: absolute;
    content: "";
    z-index: 1;
    top: 12px;
    left: 35px;
    width: 30px;
    height: 2px;
    background: #0b59db !important;
    transition: .5s;
}

.inner-style .single-feature-box:hover .feature-content a:after {
    left: -24px;
    background: #fff !important;
}



/*---=====================================================
   <!--Toptech Contact Area Css-->
=========================================================---*/
.contact-area.style-three.inner {
    padding: 122px 0 122px;
}

.style-three.inner .add-white-bg {
    margin: 0 0;
    filter: drop-shadow(0px 10px 50px rgba(217, 217, 217, 0.5));
}

.google-map iframe {
    width: 100%;
    height: 600px;
}


/*---=====================================================
   <!--Toptech Location Area Css-->
=========================================================---*/
.location-area.inner-style {
    background: #f3f8fd;
    padding: 225px 0 0px;
}

.inner-style .single-location-box {
    text-align: center;
    border-radius: 5px;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    background-color: #ffffff;
    padding: 93px 0 59px;
    position: relative;
    margin-bottom: 126px;
}

.inner-style .location-thumb {
    position: absolute;
    top: -103px;
    left: 0;
    right: 0;
    overflow: hidden;
}
.inner-style .location-thumb img{
    animation: bns1 5s linear infinite;
}
@keyframes bns1 {
    50%{
        transform: rotate(-5deg);
    }
    80%{
        transform: rotate(5deg);
    }
}
.inner-style .location-content p {
    color: #686868;
    margin: 0 0 35px;
}

.inner-style .location-content h4 {
    color: #041424;
    font-size: 26px;
    font-weight: 600;
    margin: 30px 0 13px;
}

.location-icon{
    position: relative;
    display: inline-block;
}

.location-icon:before {
    position: absolute;
    content: "";
    left: -150px;
    top: 22px;
    width: 135px;
    height: 1px;
    background-image: linear-gradient(90deg, #0b59db 0%, #f3f7fd 100%);
}
.location-icon:after {
    position: absolute;
    content: "";
    right: -150px;
    top: 22px;
    width: 135px;
    height: 1px;
    background-image: linear-gradient(90deg, #f3f7fd 0%, #0b59db 100%);
}

.inner-style .location-button a {
    border: 1.2px solid #e7e7e8;
    display: inline-block;
    padding: 15px 105px;
    border-radius: 30px;
    text-transform: uppercase;
    color: #041424;
    font-weight: 500;
    margin-top: 49px;
    transition: 0.5s;
}

.inner-style .location-button a:hover{
    background: #0c5adb;
    color:#fff;
}

/*---=====================================================
   <!--Toptech Mission Area Css-->
=========================================================---*/
.mission-area.inner-style {
    padding: 123px 0 123px;
}

.feature-area.style-three.inner {
    padding-bottom: 93px;
}

.inner-style .mission-box {
    background: #f3f8fd;
    padding: 107px 55px 59px 97px;
    margin-left: -24px;
}

.inner-style .mission-box.two {
    margin-left: 0;
}

.mission-thumb.two {
    position: relative;
    left: -23px;
}

.inner-style .mission-thumb img {
    width: 100%;
}

.inner-style .mission-content h4 {
    font-size: 42px;
    margin: 0 0 46px;
}

.inner-style .sigle-icon-desc {
    overflow: hidden;
}

.inner-style .single-icon-box {
    margin-bottom: 25px;
}

.inner-style .single-icon-thumb i {
    width: 45px;
    height: 45px;
    border-radius: 23px;
    filter: drop-shadow(0px 10px 10px rgba(207,218,235,0.2));
    background-color: #ffffff;
    display: inline-block;
    float: left;
    text-align: center;
    line-height: 45px;
    font-size: 25px;
    color: #041424;
    margin-right: 16px;
}

.inner-style .mission-button a {
    display: inline-block;
    border: 2px solid #e7e7e8;
    border-radius: 30px;
    padding: 13px 44px;
    color: #041424;
    font-weight: 500;
    margin-top: 23px;
    transition: 0.5s;
}

.inner-style .mission-button a:hover{
    background:#0c5adb;
    color:#fff;
}

/*---=====================================================
   <!--Toptech Choose us Area Css-->
=========================================================---*/
.choose-us-area.inner-style {
    padding: 124px 0 100px;
    background: url(../../assets/images/inner-images/choose-us-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.choose-us-area.inner-style .row.add-marging {
    margin-top: 106px;
    border-top: 1px solid #0c5adb;
    padding-top: 103px;
}

.choose-us-area.inner-style .style-two.section-title {
    margin-bottom: 28px;
}

.inner-style .single-choose-us-item ul li {
    font-size: 18px;
    color: #041424;
    font-weight: 400;
    font-family: "Nunito";
    margin-bottom: 19px;
    margin-left: 27px;
    position: relative;
}

.inner-style .single-choose-us-item ul li:before {
    position: absolute;
    content: "";
    width: 9px;
    height: 9px;
    left: -20px;
    top: 9px;
    background: #0c5adb;
    border-radius: 50%;
}

.inner-style .choose-us-thumb img {
    position: relative;
    left: 113px;
}

.inner-style .single-choose-us-box {
    text-align: center;
    padding: 0 3px 0;
    margin-bottom: 20px;
}

.inner-style .choose-us-content h4 {
    font-size: 24px;
    font-weight: 600;
    margin: 29px 0 18px;
}

/*---=====================================================
   <!--Toptech Team Details Area Css-->
=========================================================---*/
.team-details-area {
    padding: 122px 0 88px;
    background: url(../../assets/images/team/team-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.team-details-area .team-member-title h4 {
    font-size: 36px;
    margin: 0;
}

.team-details-area p.desc {
    margin-top: 34px;
    margin-bottom: 45px;
}

.team-details-area .contact-info-box {
    background: #fff;
    padding: 29px 25px 27px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    transition: 0.5s;
    position: relative;
    z-index: 1;
    overflow: hidden;
    gap: 11px;
}

.team-details-area .contact-info-icon i {
    display: inline-block;
    color: #0c5adb;
    border: 2px solid #0c5adb;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    line-height: 56px;
    font-size: 22px;
    transition: 0.5s;
}

.team-details-area .contact-info-content h4 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    padding: 0 0 11px 0;
    transition: 0.5s;
}

.team-details-area .contact-info-content p {
    font-size: 26px;
    color: #0c59d7;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 0;
    padding: 0;
    transition: 0.5s;
}

.team-details-area .team-details-social-icon ul li {
    font-size: 22px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
    margin-top: 49px;
    display: inline-block;
}

.team-details-area .team-details-social-icon ul li i {
    background: #e5e7e9;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    text-align: center;
    line-height: 38px;
    color: #111;
    font-size: 14px;
    margin-left: 7px;
    transition: 0.5s;
}

.team-details-area .team-details-social-icon ul li i:hover{
    background:#0C59DB;
    color:#fff;
}

.team-details-area .team-details-location-box {
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: 46px;
}

.team-details-area .team-details-location-box .contact-info-content p {
    font-size: 17px;
    color: #0c59d7;
    font-weight: 600;
    margin: 0;
    padding: 0;
    transition: 0.5s;
}

.team-details-area .team-details-skills {
    margin-top: 115px;
}

.team-details-area .team-details-skills h4 {
    font-size: 36px;
    margin: 0 0 49px;
}

.team-details-area .skill {
    margin-bottom: 38px;
    position: relative;
    overflow: hidden;
}


/*** 
====================================================================
   Toptech Search Popup css
====================================================================
***/


.search-popup{
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 99999;
    margin-top: -540px;
    transform: translateY(-100%);
    background-color: rgba(0,0,0,0.90);
    -webkit-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition: all 500ms cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition: all 1500ms cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
    -webkit-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -moz-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    -o-transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000);
    transition-timing-function: cubic-bezier(0.860, 0.000, 0.070, 1.000); /* easeInOutQuint */
}

.search-popup{
    width: 100%;
}

.search-active .search-popup{
    transform: translateY(0%);
    margin-top: 0;
}

.search-popup .close-search {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    margin-top: -200px;
    border-radius: 50%;
    text-align: center;
    background-color: #0c5adb;
    width: 70px;
    cursor: pointer;
    box-shadow: 0 0 10px rgba(0,0,0,0.05);
    border-bottom: 3px solid #fff;
    border-top: 3px solid #fff;
    border-right: 3px solid #062462;
    border-left: 3px solid #062462;
    -webkit-transition: all 500ms ease;
    height: 70px;
    line-height: 70px;
    text-align: center;
}

.search-active .search-popup .close-search{
    visibility: visible;
    opacity: 1;
    top: 50%;
    -webkit-transition-delay: 1500ms;
    -moz-transition-delay: 1500ms;
    -ms-transition-delay: 1500ms;
    -o-transition-delay: 1500ms;
    transition-delay: 1500ms;
}

.search-popup form{
    position: absolute;
    max-width: 700px;
    top: 50%;
    left: 15px;
    right: 15px;
    margin:-35px auto 0;
    transform: scaleX(0);
    transform-origin: center;
    background-color: #111111;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.search-active .search-popup form{
    transform: scaleX(1);
    -webkit-transition-delay: 1200ms;
    -moz-transition-delay: 1200ms;
    -ms-transition-delay: 1200ms;
    -o-transition-delay: 1200ms;
    transition-delay: 1200ms;
}

.search-popup .form-group{
    position:relative;
    margin:0px; 
  
    overflow: hidden;
}
.search-popup .form-group input[type="text"],
.search-popup .form-group input[type="search"]{
    position:relative;
    display:block;
    font-size:18px;
    line-height: 50px;
    color:#000000;
    height:70px;
    width:100%;
    padding: 10px 30px;
    background-color: #ffffff;
    -webkit-transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    transition:all 500ms ease;
    font-weight:500;
    text-transform:capitalize;
}

.search-popup .form-group input[type="submit"], .search-popup .form-group button {
    position: absolute;
    right: 30px;
    top: 0px;
    height: 70px;
    line-height: 70px;
    background: transparent;
    text-align: center;
    font-size: 24px;
    color: #0c5adb;
    padding: 0;
    cursor: pointer;
    -webkit-transition: all 500ms ease;
    border: none;
}

.search-popup .form-group input[type="submit"]:hover,
.search-popup .form-group button:hover{
    color: #000000;
}

.search-popup input::placeholder,
.search-popup textarea::placeholder{
    color:#000000;
}

.search-popup .close-search.style-two{
    position: absolute;
    right: 25px;
    left: auto;
    color:#ffffff;
    width:auto;
    height:auto;
    top:25px;
    margin:0px;
    border:none;
    background:none !important;
    box-shadow:none !important;
    -webkit-transition:all 500ms ease;
    -moz-transition:all 500ms ease;
    -ms-transition:all 500ms ease;
    -o-transition:all 500ms ease;
    transition:all 500ms ease;
}

.search-box-btn {
    display: inline-block;
    padding-left: 22px;
}

.search-box-btn i {
    display: inline-block;
    color: #fff;
    line-height: 30px;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
    transition: .5s;
}

.upp.search-box-btn i {
    color: #9E9FA1;
}

.search-box-btn.search-box-outer span i {
    font-size: 20px;
    display: inline-block;
    color: #fff;
    padding-left: 17px;
    position: relative;
    z-index: 1;
}

.upp.search-box-btn.search-box-outer span i {
    color: #9E9FA1;
}

.search-box-btn.search-box-outer span i:after {
    position: absolute;
    content: "0";
    right: -10px;
    top: -4px;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    background: #0c5adb;
    color: #fff;
    border-radius: 50%;
    display: inline-block;
    font-size: 12px;
}

.flaticon-multiply:before {
    content: inherit;
}

button.close-search i {
    font-size: 25px;
    color: #fff;
    display: inline-block;
}


span.flaticon-multiply i {
    display: inline-block;
    color: #0c5adb;
}






/*------Toptech Scroll Up Css------------*/
.prgoress_indicator {
    position: fixed;
    right: 26px;
    bottom: 35px;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    border-radius: 50px;
    box-shadow: inset 0 0 0 2px rgba(22, 93, 245, 0.2);
    z-index: 9999999999;
    opacity: 0;
    visibility: hidden;
    transform: translateY(15px);
    transform: translateY(15px) !important;
    transition: all 200ms linear !important;
}

.prgoress_indicator.active-progress {
  opacity: 1;
  visibility: visible;
  transform: translateY(0) !important;
}

.prgoress_indicator::after {
    position: absolute;
    content: "\f106";
    font-family: "Font Awesome 5 Free";
    text-align: center;
    line-height: 46px;
    font-size: 18px;
    color: #0c5adb;
    left: 0;
    top: 0;
    height: 46px;
    width: 46px;
    cursor: pointer;
    display: block;
    font-weight: 900;
    z-index: 1;
    transition: all 200ms linear !important;
}

.prgoress_indicator::before {
  position: absolute;
  content: "\f106";
  font-family: "Font Awesome 5 Free";
  text-align: center;
  line-height: 46px;
  font-size: 18px;
  font-weight: 900;
  opacity: 0;
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  left: 0;
  top: 0;
  height: 46px;
  width: 46px;
  cursor: pointer;
  display: block;
  z-index: 2;
  transition: all 200ms linear !important;
}
.prgoress_indicator:hover ::after {
  color: #0c5adb;
}
.prgoress_indicator:hover ::before {
  opacity: 1;
}
.prgoress_indicator svg path {
  fill: none;
}
.prgoress_indicator svg.progress-circle path {
  stroke:#0c5adb;
  stroke-width: 4;
  box-sizing: border-box;
  transition: all 200ms linear !important;
}






/*-----toptech home-4 css---*/






/*---=====================================================
   <!--Toptech Header Area Css Style foure-->
=========================================================---*/

.style-foure.sticky {
    background: #ffff !important;
}

.header-area.style-foure .header-menu {
    margin-right: -127px;
}

.header-area.style-foure .header-menu ul li {
    margin-right: 32px;
}

.header-area.style-foure {
    margin: 0;
    padding: 10px 0 10px;
}

.header-area.style-foure .header-menu ul {
    margin-right: 40px;
}

.header-area.style-foure .header-menu ul li a {
    display: inline-block;
    padding: 25px 0;
    font-size: 16px;
    color: #041424;
    font-weight: 500;
    font-family: "Nunito";
    text-transform: capitalize;
}

.header-area.style-foure .header-menu ul li a i {
    display: inline-block;
    color: #041424;
    font-size: 13px;
    margin-left: -5px;
}

.header-area.style-foure .header-button a {
    font-size: 16px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 500;
    font-family: "Nunito";
    border-radius: 30px;
    padding: 15px 35px;
    position: relative;
    z-index: 1;
}

.header-area.style-foure .header-button a::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #00004A;
    clip-path: polygon(50% 0, 50% 0, 50% 50%, 50% 100%, 50% 100%, 50% 50%);
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0;
    z-index: -1;
    transition: .5s;
    border-radius: 30px;
}

.header-area.style-foure h3.social-title {
    margin: 0;
    font-size: 18px;
    line-height: 48px;
    color: #041424;
    font-weight: 500;
    font-family: "Nunito";
    margin-right: 20px;
}

.header-area.style-foure .social-media {
    float: right;
    margin-right: -157px;
}

.header-area.style-foure ul.social-icon li {
    display: inline-block;
}

.header-area.style-foure ul.social-icon li  a i {
    display: inline-block;
    font-size: 14px;
    margin-right: 16px;
    transition: .5s;
}

.header-area.style-foure ul.social-icon li a i:hover {
    color: #0C5ADB;
}


.header-area.style-foure .header-button a:hover::before {
    clip-path: polygon(25% -70%, 75% -70%, 120% 50%, 75% 170%, 25% 170%, -20% 50%);
}


.header-area.style-foure .header-menu ul li:hover .sub-menu{
    opacity: 1;
    top: 113%;
    visibility: visible;
}

/*---=====================================================
   <!--Toptech Hero Area Css Style foure-->
=========================================================---*/
.style-foure.hero-area {
    background: url(../../assets/images/home-4/slider-bg.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 840px;
}

.hero-area.style-foure .hero-content {
    text-align: center;
}

.style-foure .hero-content h4 {
    font-size: 18px;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 500;
    margin: 0 0 18px;
    display: inline-block;
    position: relative;
    z-index: 1;
}

.style-foure .hero-content h4::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: url(../../assets/images/home-4/title-shape.png);
    background-repeat: no-repeat;
    left: -11%;
}

.style-foure .hero-content h4::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: url(../../assets/images/home-4/title-shape.png);
    background-repeat: no-repeat;
    right: -103%;
}

.style-foure .hero-content h1 {
    font-size: 66px;
    line-height: 65px;
    color: #ffffff;
    font-weight: 700;
}

.style-foure .hero-content p {
    width: 48%;
    color: #fff;
    font-size: 17px;
    line-height: 25px;
    margin: 26px 0 0;
}

.style-foure.hero-button {
    margin-top: 71px;
    text-align: center;
}

.style-foure .hero-button.style-one a {
    display: inline-block;
    background: #0c5adb;
    color: #fff;
    padding: 13px 45px;
    font-weight: 500;
    font-size: 17px;
    margin-right: 14px;
}

.style-foure a.button-two {
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Nunito";
    background: #0C59DB;
    padding: 17px 34px;
    border-radius: 30px;
    position: relative;
    z-index: 1;
    transition: .5s;
}

.style-foure a.button-two::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #00004A;
    clip-path: polygon(50% 0, 50% 0, 50% 50%, 50% 100%, 50% 100%, 50% 50%);
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0;
    transition: .5s;
    border-radius: 30px;
}

.style-foure a.button-two:hover::before {
    clip-path: polygon(25% -70%, 75% -70%, 120% 50%, 75% 170%, 25% 170%, -20% 50%);
}

.hero_list .owl-nav {
    position: relative;
}

.hero_list .owl-prev {
    position: absolute;
    top: -442px;
    left: 137px;
}

.hero_list .owl-next {
    display: inline-block;
    position: absolute;
    top: -442px;
    right: 137px;
    z-index: 1;
}

.hero_list .owl-prev {
    height: 60px;
    width: 60px;
    line-height: 60px !important;
    text-align: center;
    border-radius: 50%;
    transition: .5s;
    border: 2px solid #818181 !important;
}

.hero_list .owl-next i {
    color: #fff;
    font-size: 19px;
}

.hero_list .owl-next {
    height: 60px;
    width: 60px;
    line-height: 60px !important;
    text-align: center;
    border-radius: 50%;
    transition: .5s;
    border: 2px solid #818181 !important;
}

.hero_list .owl-prev i {
    color: #fff;
    font-size: 19px;
}

.hero_list .owl-next:hover{
	background: #0C59DB !important;
	color: #fff;
    border: 2px solid #0C59DB !important;
}

.hero_list .owl-prev:hover{
	background: #0C59DB !important;
	color: #fff;
    border: 2px solid #0C59DB !important;
}




/*---=====================================================
   <!--Toptech brand Area Css Style foure-->
=========================================================---*/

.brand-area.style-foure {
    background: #000049;
    padding: 43px 0 80px;
}

.brand-area .brand-box {
    text-align: center;
}

.brand-area .owl-carousel .owl-item img {
    display: inline;
    width: inherit;
    text-align: center;
}





/*---=====================================================
   <!--Toptech section-ti8tle Css Style foure-->
=========================================================---*/

.section-title.style-foure.text-left {
    margin-bottom: 54px;
}

.section-title.style-foure.text-center {
    margin-bottom: 60px;
    position: relative;
    z-index: 1;
}

.section-title.style-foure.text-center h5.section-sub-title::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: url(../../assets/images/home-4/title-shape2.png);
    background-repeat: no-repeat;
    left: 3%;
    right: 0;
    margin: auto;
}

.section-title.style-foure.text-center h5.section-sub-title::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: url(../../assets/images/home-4/title-shape2.png);
    background-repeat: no-repeat;
    left: 103%;
    right: 0;
    margin: auto;
    top: 3px;
}

.section-title.style-foure h5.section-sub-title {
    font-size: 18px;
    text-transform: uppercase;
    color: #0c5adb;
    font-weight: 500;
    margin: 0;
    margin-bottom: 12px;
    padding-left: 29px;
    position: relative;
    z-index: 1;
    display: inline-block;
}

.section-title.style-foure h5.section-sub-title::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: url(../../assets/images/home-4/title-shape2.png);
    background-repeat: no-repeat;
    left: 0;
    top: 4px;
}

.section-title.style-foure h1.section-main-title {
    font-size: 46px;
    line-height: 45px;
    color: #041424;
    font-weight: 700;
}

.section-title.style-foure p.description {
    font-size: 18px;
    line-height: 28px;
    color: #686868;
    font-weight: 400;
    font-family: "Nunito";
    margin: 0;
    padding: 19px 0 0;
    width: 85%;
}

.about-area.style-foure .section-title.style-foure.text-left {
    margin-bottom: 28px;
}

.works-area.style-foure .section-title.style-foure.text-left {
    margin-bottom: 0;
}

.counter-area.style-foure .section-title.style-foure h1.section-main-title {
    color: #fff;
}

.team-area.style-foure .section-title.style-foure h1.section-main-title {
    color: #fff;
}

.awards-one .section-title.style-foure p.description {
    padding: 35px 0 0;
    width: 98%;
}

/*---=====================================================
   <!--Toptech service Area Css Style foure-->
=========================================================---*/


.service-area.style-foure {
    background: #F3F8FC;
    padding: 113px 0 88px;
}

.service-area.style-foure .service-single-box {
    background: #ffff;
    padding: 39px 0 30px 0;
    border-radius: 10px;
    margin: 0 0 0 35px;
    margin-bottom: 30px;
    position: relative;
    z-index: 1;
}

.service-area.style-foure h3.service-title {
    font-size: 22px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 0;
    margin-bottom: 12px;
}

.service-area.style-foure p.service-desc {
    font-size: 16px;
    line-height: 28px;
    color: #686868;
    font-weight: 400;
    font-family: "Nunito";
    margin: 0;
    overflow: hidden;
}

.service-area.style-foure .service-icon {
    height: 88px;
    width: 88px;
    line-height: 88px;
    text-align: center;
    background: #0B5AD9;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    float: left;
    margin-right: 24px;
    margin-left: -33px;
}

.service-area.style-foure .service-icon::before {
    position: absolute;
    content: "";
    height: 75%;
    width: 75%;
    border: 1px dashed #fff;
    border-radius: 50%;
    left: 11px;
    top: 11px;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.service-area.style-foure .service-icon-two {
    position: absolute;
    bottom: -25%;
    right: -9%;
    opacity: 0;
    transition: .5s;
}

.service-area.style-foure .service-icon-two a i {
    display: inline-block;
    height: 40px;
    width: 40px;
    background: #0B5AD9;
    color: #fff;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    border-radius: 5px 0 10px 0;
}


.service-area.style-foure .service-single-box:hover .service-icon-two{
    bottom: 0;
    right: 0;
    opacity: 1;
}






/*---=====================================================
   <!--Toptech about Area Css Style foure-->
=========================================================---*/



.about-area.style-foure {
    padding: 110px 0 120px;
}

.about-area.style-foure .about-title.style-two {
    margin-left: -45px;
}

.about-area.style-foure .about-title h3 {
    font-size: 18px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 0;
    padding-left: 24px;
    position: relative;
    z-index: 1;
    margin-bottom: 18px;
}

.about-area.style-foure .about-title h3::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: url(../../assets/images/home-4/title-shape2.png);
    background-repeat: no-repeat;
    left: 0;
    top: 5px;
}

.dreamit-button {
    margin-top: 30px;
    display: inline-block;
}

.dreamit-button a {
    display: inline-block;
    background: #0c5adb;
    padding: 15px 35px;
    margin-right: 14px;
    position: relative;
    border-radius: 30px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Nunito";
    z-index: 1;
}

.dreamit-button a::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #00004A;
    border-radius: 30px;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    transition: .5s;
    clip-path: polygon(50% 0, 50% 0, 50% 50%, 50% 100%, 50% 100%, 50% 50%);
    z-index: -1;
}

.dreamit-button a:hover::before {
    clip-path: polygon(25% -70%, 75% -70%, 120% 50%, 75% 170%, 25% 170%, -20% 50%);
}



.about-area.style-foure .about-thumb {
    position: relative;
    z-index: 1;
}

.about-area.style-foure .counter-box {
    display: flex;
    position: absolute;
    bottom: 12%;
    right: 4%;
}

.about-area.style-foure .counter-number h1 {
    background: #000049;
    font-size: 60px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Rajdhani";
    padding: 16px 27px 12px;
}

.about-area.style-foure .counter-title h3 {
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Rajdhani";
    background: #0C59DB;
    padding: 22px 37px 22px;
    border-radius: 0 0 10px 0;
}







/*---=====================================================
   <!--Toptech about Area Css Style foure-->
=========================================================---*/


.counter-area.style-foure {
    background: #00004A;
    padding: 113px 0 86px;
}

.counter-area.style-foure .row.service {
    padding: 74px 0 0;
}

.counter-area.style-foure .counter-single-box-tow {
    margin-bottom: 30px;
}

.counter-area.style-foure .counter-number-tow h1 {
    display: inline-block;
    font-size: 70px;
    line-height: 56px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Rajdhani";
}

.counter-area.style-foure .counter-number-tow {
    float: left;
    margin-right: 30px;
    position: relative;
    z-index: 1;
}
.counter-area.style-foure .counter-number-tow::before {
    position: absolute;
    content: "";
    height: 1px;
    width: 100%;
    background: #0C5BDA;
    bottom: -25%;
}

.counter-area.style-foure .counter-content h4 {
    font-size: 22px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 0;
    margin-bottom: 9px;
}

.counter-area.style-foure .counter-content p {
    opacity: 0.600;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
    margin: 0;
}

.counter-area.style-foure .dreamit-button {
    margin-top: 45px;
    float: right;
}

.counter-area.style-foure .dreamit-button a {
    background: #262664;
    text-transform: uppercase;
    margin-right: 0;
}

.counter-area.style-foure .dreamit-button a::before {
    background: #0C59DB;
}

.counter-area.style-foure .service-single-box-tow {
    border: 1px solid #262664;
    border-radius: 10px;
    padding: 32px 32px 31px;
    margin-bottom: 30px;
    transition: .5s;
}

.counter-area.style-foure h3.service-title-tow {
    font-size: 24px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 0;
    padding: 28px 0 17px;
}

.counter-area.style-foure p.service-desc-two {
    opacity: 0.600;
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
    margin: 0;
    padding: 0 0 35px;
}

.counter-area.style-foure .service-btn-tow a {
    display: inline-block;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Nunito";
    background: #262664;
    border-radius: 30px;
    padding: 12px 29px 12px;
    position: relative;
    z-index: 1;
}

.counter-area.style-foure .service-btn-tow a::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #0C59DB;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 30px;
    transform: scale(0);
    transition: .5s;
}

.counter-area.style-foure .service-btn-tow a:hover::before {
    transform: scale(1);
}

.counter-area.style-foure .service-single-box-tow:hover {
    margin-top: -10px;
}






/*---=====================================================
   <!--Toptech work Area Css Style foure-->
=========================================================---*/



.work-area.style-foure {
    padding: 111px 0 88px;
}

.work-area.style-foure .work-single-box {
    margin-bottom: 30px;
}

.work-area.style-foure .work-thumb {
    position: relative;
    z-index: 1;
}

.work-area.style-foure .work-icon {
    position: absolute;
    top: 19%;
    text-align: center;
    left: 0;
    right: 0;
    margin: auto;
}

.work-area.style-foure .work-icon a i {
    display: inline-block;
    background: #0E59D9;
    color: #fff;
    font-size: 22px;
    height: 85px;
    width: 85px;
    line-height: 85px;
    border-radius: 50%;
    position: relative;
    z-index: 1;
    transform: scale(0);
    transition: .5s;
}

.work-area.style-foure .work-icon a i::after {
    position: absolute;
    content: "";
    height: 62px;
    width: 62px;
    border: 1px dashed #fff;
    left: 14%;
    top: 14%;
    border-radius: 50%;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.work-area.style-foure .work-content {
    position: absolute;
    bottom: 9px;
    margin-left: 35px;
}

.work-area.style-foure a h1.work-title {
    display: inline;
    font-size: 24px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Rajdhani";
    margin-top: 0;
}

.work-area.style-foure p.worK-text {
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
}

.work-area.style-foure .work-single-box:hover .work-icon a i {
    transform: scale(1);
}







/*---=====================================================
   <!--Toptech works Area Css Style foure-->
=========================================================---*/

.works-area.style-foure {
    background: #F3F8FC;
    padding: 120px 0 120px;
}

.works-area.style-foure .works-thumb {
    position: relative;
    z-index: 1;
}

.works-area.style-foure .works-video-icon {
    position: absolute;
    top: 39%;
    left: 40%;
}

.works-area.style-foure .works-video-icon a {
    display: inline-block;
    background: #0C59DB;
    font-size: 20px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Rajdhani";
    height: 100px;
    width: 100px;
    line-height: 100px;
    text-align: center;
    border-radius: 50%;
    position: relative;
    z-index: 1;
}

.works-area.style-foure .works-video-icon a::before {
    position: absolute;
    content: "";
    height: 80px;
    width: 80px;
    border: 1px dashed #ffff;
    left: 10%;
    top: 11%;
    border-radius: 50%;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.works-area.style-foure p.works {
    font-size: 18px;
    line-height: 26px;
    color: #686868;
    font-weight: 400;
    font-family: "Nunito";
    padding: 30px 0 0;
    width: 84%;
}

.dreamit-button2 {
    display: inline-block;
}

.dreamit-button2 a {
    display: inline-block;
    font-size: 16px;
    line-height: 48px;
    color: #686868;
    font-weight: 500;
    font-family: "Nunito";
}

.dreamit-button2 a i {
    display: inline-block;
    font-size: 18px;
    margin-left: 4px;
}





/*---=====================================================
   <!--Toptech project Area Css Style foure-->
=========================================================---*/


.project-area.style-foure {
    padding: 120px 0 82px;
    background: url(../../assets/images/home-4/project-bg.png);
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.project-area.style-foure .project-single-box {
    margin-bottom: 30px;
}

.project-area.style-foure h1.project-number {
    font-size: 24px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Rajdhani";
    height: 75px;
    width: 75px;
    line-height: 75px;
    text-align: center;
    border-radius: 50%;
    background: rgba(255,255,255,0.1);
    position: relative;
    z-index: 1;
    margin-bottom: 27px;
    margin-top: 0;
}

.project-area.style-foure h1.project-number::before {
    position: absolute;
    content: "";
    height: 55px;
    width: 55px;
    border: 1px dashed #fff;
    left: 14%;
    top: 14%;
    border-radius: 50%;
    -webkit-animation-name: rotateme;
    -webkit-animation-duration: 15s;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

.project-area.style-foure h3.project-title {
    font-size: 22px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 0;
}

.project-area.style-foure p.project-desc {
    opacity: 0.600;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
    margin: 0;
    padding: 12px 0 14px;
}

.project-area.style-foure .project-btn a {
    display: inline-block;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Nunito";
    position: relative;
    z-index: 1;
}

.project-area.style-foure .project-btn a::before {
    position: absolute;
    content: "";
    height: 1px;
    background: #fff;
    bottom: 0;
    transition: .5s;
    width: 0;
}

.project-area.style-foure .project-btn a i {
    display: inline-block;
    font-size: 18px;
    position: relative;
    top: 1px;
    margin-left: 4px;
}

.project-area.style-foure .project-btn a:hover::before {
    width: 100%;
}




/*---=====================================================
   <!--Toptech Team Area Css Style foure-->
=========================================================---*/


.team-area.style-foure {
    background: #00004A;
    padding: 112px 0 111px;
}

.team-area.style-foure .team-single-box {
    overflow: hidden;
    margin-bottom: 30px;
}

.team-area.style-foure .team-single-box.style-twos {
    margin-top: 50px;
}

.team-area.style-foure .team-single-box.style-foure {
    margin-top: 50px;
}

.team-area.style-foure .team-thumb {
    position: relative;
    z-index: 1;
}

.team-area.style-foure .team-thumb img {
    width: 100%;
}

.team-area.style-foure .team-social-icon {
    position: absolute;
    top: -37%;
    right: 10%;
    opacity: 0;
    transition: .5s;
}

.team-area.style-foure .team-social-icon a i {
    display: inline-block;
    color: #ffff;
    font-size: 15px;
    background: #0C59DB;
    height: 40px;
    width: 40px;
    line-height: 37px;
    text-align: center;
    margin-bottom: 10px;
    border-radius: 50%;
    border: 2px solid #fff;
    position: relative;
    z-index: 1;
}

.team-area.style-foure .team-social-icon a i:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #00004A;
    left: 0;
    z-index: -1;
    border-radius: 50%;
    transition: .5s;
    transform: scale(0);
}

.team-area.style-foure .team-content {
    position: absolute;
    bottom: 10%;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.team-area.style-foure h3.team-title {
    font-size: 22px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 0;
}

.team-area.style-foure p.team-text {
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
    margin: 0;
}

.team-area.style-foure .team-bottom-title h3 {
    font-size: 16px;
    line-height: 26px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Rajdhani";
    text-align: center;
    padding: 32px 0 0;
    display: block;
    position: relative;
    z-index: 1;
}

.team-area.style-foure .team-bottom-title h3 span {
    display: inline-block;
    color: #0c5adb;
}

.team-area.style-foure .team-bottom-title h3::before {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: url(../../assets/images/home-4/team-shape.png);
    background-repeat: no-repeat;
    left: 0;
    top: 78%;
}

.team-area.style-foure .team-bottom-title h3::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: url(../../assets/images/home-4/team-shape2.png);
    background-repeat: no-repeat;
    left: 70%;
    top: 78%;
}

.team-area.style-foure .team-social-icon a i:hover:after {
    transform: scale(1);
}

.team-area.style-foure .team-single-box:hover .team-social-icon {
    top: 16%;
    opacity: 1;
}





/*---=====================================================
   <!--Toptech testimonial Area Css Style foure-->
=========================================================---*/



.testimonial-area.style-foure {
    padding: 111px 0 89px;
}

.testimonial-area.style-foure .testi-single-box {
    background: #F3F7FD;
    padding: 36px 37px 31px;
    margin-bottom: 30px;
    border-radius: 10px;
}

.testimonial-area.style-foure .testi-author-box {
    padding: 0 0 30px;
    border-bottom: 1px solid #DEE2E7;
}

.testimonial-area.style-foure .testi-top-content {
    margin-left: 24px;
    margin-top: 3px;
}

.testimonial-area.style-foure h3.testi-title {
    font-size: 24px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
    margin: 0;
    line-height: 25px;
}

.testimonial-area.style-foure .testi-rating {
    padding: 21px 0 33px;
}

.testimonial-area.style-foure span.testi-text {
    font-size: 16px;
    color: #686868;
    font-weight: 400;
    font-family: "Nunito";
    display: inline-block;
}

.testimonial-area.style-foure .testi-rating ul li {
    display: inline-block;
}

.testimonial-area.style-foure .testi-rating ul li i {
    display: inline-block;
    background: #0D5ADC;
    color: #fff;
    font-size: 10px;
    height: 19px;
    width: 19px;
    line-height: 19px;
    text-align: center;
    margin-left: -2px;
}

.testimonial-area.style-foure h4.testi-date {
    font-size: 15px;
    color: #686868;
    font-weight: 400;
    font-family: "Nunito";
    margin: 0;
    margin-left: 44%;
}

.testimonial-area.style-foure p.testi-dasc {
    font-size: 17px;
    line-height: 26px;
    color: #686868;
    font-weight: 400;
    font-family: "Nunito";
    margin: 0;
}





/*=============================
Awards One Css
===============================*/
.awards-one {
    position: relative;
    display: block;
    background: var(--thm-black);
    padding: 120px 0px 120px;
    z-index: 1;
    background: #F3F8FC;
}

.text-box span {
    display: inline-block;
    font-size: 20px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
    background-color: #ffffff;
    border: 1px solid rgba(30,30,30,0.08);
    height: 45px;
    width: 45px;
    text-align: center;
    border-radius: 50%;
    line-height: 45px;
    margin-right: 63px;
}

.text-box i {
    display: inline-block;
    float: right;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    background-color: #ffffff;
    border: 1px solid rgba(30,30,30,0.08);
    line-height: 52px;
    text-align: center;
    font-size: 22px;
    position: relative;
    z-index: 1;
    transition: .5s;
}

.text-box i::after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #0C59DB;
    left: 0;
    top: 0;
    border-radius: 50%;
    z-index: -1;
    transition: .5s;
    transform: scale(0);
}

.text-box i:hover {
    color: #fff;
}

.text-box i:hover::after {
    transform: scale(1);
}

.text-box i {
    float: right;
}

.awards-one .sec-title h2 {
    color: var(--thm-white);
}

.awards-one__inner {
    position: relative;
    display: block;
}

.awards-one__inner ul {
    position: relative;
    display: block;
}

.awards-one__single {
    position: relative;
}

.awards-one__inner ul li {
    position: relative;
    padding: 43px 0px 43px;
    transition: all 200ms linear;
    transition-delay: 0.1s;
    border-bottom: 1px solid rgba(30,30,30,0.08);
}

.awards-one__inner ul li::before {
    position: absolute;
    left: 0;
    bottom: -2px;
    right: 0;
    height: 2px;
    background: var(--thm-primary);
    transform: scaleX(0.9) rotateX(0deg);
    transition: all 0.4s linear;
    opacity: 0;
    content: "";
}

.awards-one__inner ul li:hover::before {
    transform: scaleX(1.0) rotateX(0deg);
    transition: all 0.4s linear;
    opacity: 1;
}

.awards-one__inner ul li:first-child {
    border-bottom: 1px solid rgba(30,30,30,0.08);
}

.awards-one__inner ul li .content-box {
    position: relative;
    align-items: center;
}

.awards-one__inner ul li .date-box {
    position: relative;
    display: block;
    text-align: center;
}

.awards-one__inner ul li .date-box span {
    color: #fff;
    font-size: 16px;
    line-height: 16px;
    font-weight: 400;
    text-transform: uppercase;
}

.awards-one__inner ul li .date-box h2 {
    color: #fff;
    font-size: 40px;
    line-height: 40px;
    font-weight: 700;
}

.awards-one__inner ul li .logo-box {
    position: relative;
    display: block;
    margin-left: 130px;
}

.awards-one__inner ul li .logo-box img {
    width: 100%;
}

.awards-one__inner ul li .text-box {
    position: relative;
    display: block;
    margin-left: 0;
}

.awards-one__inner ul li .text-box p {
    color: #000;
}

.awards-one__inner ul li .text-box h2 {
    font-size: 26px;
    line-height: 36px;
    font-weight: 700;
    margin-top: 0;
}

.awards-one__inner ul li .text-box h2 a {
    font-size: 26px;
    color: #041424;
    font-weight: 600;
    font-family: "Rajdhani";
}

.awards-one__inner ul li .text-box h2 a:hover {
    color: #0C5ADB;
}

.awards-one__img {
    position: absolute;
    width: 264px;
    height: 264px;
    top: 50px;
    left: 185px;
    pointer-events: none;
    transform: translate(-100%, -50%);
    overflow: hidden;
    border-radius: 0%;
    opacity: 0;
    transform: scale(0.8);
    transition: all .5s ease-out;
    z-index: 9;
}

.awards-one__img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
    transition: transform .4s ease-out;
}







/*---=====================================================
   <!--Toptech Footer Area Css Style foure-->
=========================================================---*/


.footer-area.style-foure {
    padding: 120px 0 96px;
    background: #00004A;
}

.footer-area.style-foure .row.footer-top {
    padding: 0 0 97px;
    border-bottom: 1px solid #1A1A5C;
}

.footer-area.style-foure .row.footer-bottom {
    padding: 92px 0 0;
}

.footer-area.style-foure .footer-top-social-icon {
    text-align: right;
}

.footer-area.style-foure .footer-top-social-icon ul li {
    display: inline-block;
}

.footer-area.style-foure .footer-top-social-icon ul li a i {
    display: inline-block;
    font-size: 14px;
    color: #fff;
    background: #031B75;
    height: 35px;
    width: 35px;
    line-height: 35px;
    text-align: center;
    border-radius: 50%;
    margin-left: 15px;
    position: relative;
    z-index: 1;
}

.footer-area.style-foure .footer-top-social-icon ul li a i:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    background: #0E59D9;
    left: 0;
    top: 0;
    z-index: -1;
    border-radius: 50%;
    transform: scale(0);
    transition: .5s;
}

.footer-area.style-foure .footer-widget-title h4 {
    font-size: 24px;
    color: #ffffff;
    font-weight: 700;
    font-family: "Nunito";
    margin-top: 0;
    margin-bottom: 32px;
}

.footer-area.style-foure .footer-widget-menu ul li a {
    display: inline-block;
    opacity: 0.600;
    font-size: 16px;
    line-height: 41px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
}

.footer-area.style-foure .footer-widget-menu ul li {
    font-size: 16px;
    line-height: 51px;
    font-weight: 400;
    font-family: "Nunito";
    color: #B9B7C5;
}

.footer-area.style-foure li.footer-descrip {
    line-height: 28px !important;
    margin-top: 11px;
}

.footer-area.style-foure .footer-widget-menu ul li i {
    display: inline-block;
    color: #fff;
    font-size: 22px;
    position: relative;
    top: 4px;
    margin-right: 20px;
}

.footer-area.style-foure .footer-widget-content p {
    font-size: 16px;
    line-height: 28px;
    color: #B9B7C5;
    font-weight: 400;
    font-family: "Nunito";
    margin: 0;
    margin-bottom: 39px;
}

.footer-area.style-foure .footer-newslatter-box input {
    height: 56px;
    border: none;
    outline: 0;
    border-radius: 30px;
    background: #031B75;
    width: 83%;
    padding: 0 25px 0;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
}

.footer-area.style-foure .footer-newslatter-box input::placeholder {
    opacity: 0.600;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
    font-family: "Nunito";
}

.footer-area.style-foure .footer-newslatter-box button {
    height: 56px;
    border-radius: 30px;
    font-size: 16px;
    color: #ffffff;
    font-weight: 500;
    font-family: "Nunito";
    background: #0C59DB;
    border: none;
    width: 42%;
    margin-left: -83px;
}

.footer-area.style-foure .footer-top-social-icon ul li a i:hover:after {
    transform: scale(1);
}



/*---=====================================================
   <!--Toptech footer-bottom-area.style-foure-->
=========================================================---*/



.footer-bottom-area.style-foure {
    background: #0D0D53;
    padding: 22px 0 22px;
}

.footer-bottom-content p {
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
    margin: 0;
}

.footer-botton-list {
    text-align: right;
}

.footer-botton-list ul li {
    display: inline-block;
}

.footer-botton-list ul li  a {
    display: inline-block;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    font-weight: 400;
    margin-left: 25px;
}






/*===========================
<-- Loader Css -->
=============================*/
.loader-wrapper {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transition: 0.8s 1s ease;
    z-index: 99999;
}

.loader {
    position: relative;
    display: block;
    z-index: 201;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 100px;
    margin: -50px 0 0 -50px;
    border-radius: 50%;
    transition: all 1s 1s ease;
    border: 3px solid transparent;
    border-top-color: #f3297c;
    -webkit-animation: spin 1.5s linear infinite;
    -moz-animation: spin 1.5s linear infinite;
    -o-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}

.loader:before {
    position: absolute;
    content: '';
    top: 6px;
    left: 6px;
    right: 6px;
    bottom: 6px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-left-color:#0c5adb;
    -webkit-animation: spin 2s linear infinite;
    -moz-animation: spin 2s linear infinite;
    -o-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}

.loader:after {
    position: absolute;
    content: '';
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-right-color: #fff;
    -webkit-animation: spin 2.5s linear infinite;
    -moz-animation: spin 2.5s linear infinite;
    -o-animation: spin 2.5s linear infinite;
    animation: spin 2.5s linear infinite;
}

/*/ Here comes the Magic /*/

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-moz-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.loader-wrapper .loder-section {
    position: fixed;
    top: 0;
    width: 50%;
    height: 100%;
    background: transparent; /* Fully transparent background */
    z-index: 2;
    opacity: 0; /* Start fully transparent */
    transition: opacity 0.5s ease-in-out; /* Transition for fade-in effect */
}

.loader-wrapper.loaded .loder-section {
    opacity: 1; /* Fade in to fully visible */
}

.loader-wrapper .loder-section.left-section {
    left: 0;
    transition: 1s 1.4s ease;
}

.loader-wrapper .loder-section.right-section {
    right: 0;
    transition: 1s 1.4s ease;
}

/*/ When page loaded /*/
.loaded .loder-section.left-section {
    left: -100%;
}

.loaded .loder-section.right-section {
    right: -100%;
}

.loaded .loader-wrapper {
    visibility: hidden;
}

.loaded .loader {
    top: -100%;
    opacity: 0;
}
